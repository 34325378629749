import { useState, useEffect, useContext } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'
import L from 'leaflet'

import GameContext from '../../contexts/GameContext'
import { getCoord } from '../../services/map'

// import { DefaultIcon } from './MapInput'

const PalIcon = new L.Icon({
  iconUrl:
    'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_04.png',
  iconSize: [50, 50],
})

const Map = ({ sources }) => {
  const { game } = useContext(GameContext)
  const [locIcons, setLocIcons] = useState({})

  useEffect(() => {
    sources.forEach((loc) => {
      if (loc.img && !locIcons[loc.img])
        locIcons[loc.img] = new L.Icon({
          iconUrl: 'https://' + game.cdn + loc.img,
          iconSize: [50, 50],
        })

      if (loc.imgs && !locIcons[loc.imgs[0]])
        locIcons[loc.img] = new L.Icon({
          iconUrl: 'https://' + game.cdn + loc.imgs[0],
          iconSize: [50, 50],
        })
      setLocIcons({ ...locIcons })
    })
  }, [sources?.length])

  const source = sources[0]
  return (
    <MapContainer
      center={
        source?.lat
          ? source?.raw
            ? [source.lat, source.lng]
            : getCoord(source.lat, source.lng)
          : [-128, 128]
      }
      zoom={2}
      minZoom={1}
      maxZoom={4}
      zoomControl={false}
      style={{ height: 300 }}
      crs={L.CRS.Simple}
      preferCanvas
    >
      <TileLayer url='https://cdn.zeldabuilds.gg/palworld/map/main/{z}/{x}/{y}.png' />
      {sources.map((location) => {
        const coord = location.raw
          ? [location.lat, location.lng]
          : getCoord(location.lat, location.lng)
        return location.radius > 0 ? (
          <Circle
            center={coord}
            radius={location.radius}
            pathOptions={{ color: 'blue' }}
          />
        ) : (
          <Marker
            position={coord}
            icon={
              (location.img && locIcons[location.img]) ||
              (location.imgs && locIcons[location.imgs[0]])
                ? locIcons[location.img]
                : PalIcon
            }
          >
            <Popup>{location.name}</Popup>
          </Marker>
        )
      })}
    </MapContainer>
  )
}

export default Map
