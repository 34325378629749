import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import http from '../../services/http'
import GameContext from '../../contexts/GameContext'

import Helmet from '../../components/Helmet'
import Comps from '../../components/Comps'
import Sources from '../../components/Sources'
import ImageC from '../../components/Image'

import './style.css'

const Event = () => {
  const [event, setEvent] = useState({})
  const { game } = useContext(GameContext) 

  const params = useParams()

  useEffect(() => {
    http.get('/api/events', { id: params.id, comps: true }).then((res) => {
      if (res.error) return alert(res.error)
      setEvent(res.data)
    })
  }, [])

  return (
    <div className='container-fluid event-page'>
      <Helmet data={{ name: event.name }} />
      <div className='event-info'>
        <div className='event-img'>
          <ImageC
            src={`https://${game.cdn}${event.imgs ? event.imgs[0] : ''}`}
            className='event-page-event-img'
          />
        </div>
        <div>
          <h1 className='event-title'>{event.name} Builds</h1>
          <div>{event.description}</div>
          {event.sources && <Sources sources={event.sources} />}
        </div>
      </div>
      {event.comps && (
        <Comps
          comps={event.comps}
          setComps={(newComps) => {
            event.comps = newComps
            setEvent({ ...event })
          }}
          events
        />
      )}
    </div>
  )
}

export default Event
