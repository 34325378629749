import { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

import GameContext from '../../contexts/GameContext'
import UnitSelect from '../../components/Units/UnitSelect'
import Unit from '../../components/Units/Unit'

import { Graph } from '../../services/graph'
import Helmet from '../../components/Helmet'
import AdSlot from '../../components/Ads/AdSlot'
import { StyledInput, StyledLink } from '../../components/Styled'

import breeding from './breeding.json'
import reverse from './breeding_reverse.json'

import './style.css'

const findShortest = (pal1, child) => {
  const graph = new Graph()

  const combos = []
  Object.keys(breeding).forEach((pal1) => {
    Object.keys(breeding).forEach((pal2) => {
      combos.push([pal1, pal2])
      graph.addVertex(breeding[pal1][pal2])
    })
  })

  Object.keys(breeding).forEach((pal1) => {
    Object.keys(breeding[pal1]).forEach((pal2) => {
      graph.addEdge(breeding[pal1][pal2], pal1)
      graph.addEdge(breeding[pal1][pal2], pal2)
    })
  })

  const res = graph.shortestPath(pal1, child)
  if (!res) return { res: [] }

  let found = []
  let otherPal = ''
  for (let i = 0; i < res.length - 1; i++) {
    const pal2 = res[i]
    const child = res[i + 1]
    let pal1 = Object.keys(breeding[pal2]).find((pal1) => {
      return breeding[pal2][pal1] === child
    })
    if (!pal1 && otherPal)
      pal1 = Object.keys(breeding[otherPal]).find((pal1) => {
        return breeding[otherPal][pal1] === child
      })
    if (!pal1) break
    otherPal = pal1
    found.push([pal2, pal1])
  }
  return { res, found }
}

const Calculator = () => {
  const { game } = useContext(GameContext)
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.substring(1))

  const [units, setUnits] = useState([])
  const [search, setSearch] = useState('')
  const [shortest, setShortest] = useState('')

  const unitsById = {}
  const unitsBySlug = {}
  units.forEach((unit) => {
    unitsById[unit.id] = unit
    unitsBySlug[unit.slug] = unit
  })

  const unit1 = unitsBySlug[query.parent1] || {}
  const unit2 = unitsBySlug[query.parent2] || {}
  const unit3 = unitsBySlug[query.child] || {}

  const child =
    unit1.id && unit2.id
      ? units.find((u) => u.id === breeding[unit1.id][unit2.id])
      : {}

  const shortUnit1 = unitsBySlug[query.shortUnit1] || {}
  const shortUnit2 = unitsBySlug[query.shortUnit2] || {}

  const parentOptions = reverse[unit3.id] || []

  return (
    <div className='calculator-page container-fluid'>
      <Helmet />
      <AdSlot name='leaderboard' index={0} />
      <h1>{game.name} Breeding Calculator</h1>
      <div className='calculator'>
        <div className='calculator-unit'>
          <h2 className='capitalize'>Parent {game.getText('unit')} 1</h2>
          <Unit unit={unit1} link />
          <br />
          <UnitSelect
            noValue
            type='pals'
            onChange={(option) => {
              query.parent1 = option.slug
              navigate({ search: qs.stringify(query) })
            }}
            onLoad={(units) => {
              setUnits(units)
            }}
          />
        </div>
        <span className='calc-sign'>+</span>
        <div className='calculator-unit'>
          <h2 className='capitalize'>Parent {game.getText('unit')} 2</h2>
          <Unit unit={unit2} link />
          <br />
          <UnitSelect
            noValue
            type='pals'
            units={units}
            onChange={(option) => {
              query.parent2 = option.slug
              navigate({ search: qs.stringify(query) })
            }}
          />
        </div>
        <span className='calc-sign'>=</span>
        <div className='calculator-child'>
          <h2 className='capitalize'>Child {game.getText('unit')}</h2>
          {child && (
            <>
              <Unit unit={child} link />
            </>
          )}
        </div>
      </div>

      <hr />

      <h1>{game.name} Reverse Breeding Calculator</h1>
      <div className='calculator-unit'>
        <div style={{ maxWidth: 350 }}>
          <h2 className='capitalize'>Child {game.getText('unit')}</h2>
          <Unit unit={unit3} link />
          <br />
          <UnitSelect
            noValue
            type='pals'
            units={units}
            onChange={(option) => {
              query.child = option.slug
              navigate({ search: qs.stringify(query) })
            }}
          />
        </div>
        <h2>Possible Parents</h2>
        <p>
          Parents are sorted by combined rarity to give you the easiest options
          for breeding.
        </p>
        <StyledInput
          placeholder='Filter parents'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
        <div className='reverse-breed'>
          {parentOptions
            .filter((pair) => {
              const parent1 = unitsById[pair[0]]
              const parent2 = unitsById[pair[1]]
              return (
                parent1.name.toLowerCase().includes(search.toLowerCase()) ||
                parent2.name.toLowerCase().includes(search.toLowerCase())
              )
            })
            .sort((a, b) => {
              const parenta1 = unitsById[a[0]]
              const parenta2 = unitsById[a[1]]
              const parentb1 = unitsById[b[0]]
              const parentb2 = unitsById[b[1]]
              const rarity1 =
                parenta1.tags.find((tag) => tag.tag === 'Rarity')?.number +
                parenta2.tags.find((tag) => tag.tag === 'Rarity')?.number
              const rarity2 =
                parentb1.tags.find((tag) => tag.tag === 'Rarity')?.number +
                parentb2.tags.find((tag) => tag.tag === 'Rarity')?.number
              return rarity1 - rarity2
            })
            .map((pair) => {
              const parent1 = unitsById[pair[0]]
              const parent2 = unitsById[pair[1]]
              return (
                <div className='reverse-pair'>
                  <div className='reverse-unit'>
                    <StyledLink
                      to={`/${game.getText('unit')}s/${parent1.slug}`}
                    >
                      <img
                        src={`https://${game.cdn}${parent1.img}`}
                        className='reverse-unit-img'
                      />
                      <div>{parent1.name}</div>
                    </StyledLink>
                  </div>
                  +
                  <div className='reverse-unit'>
                    <StyledLink
                      to={`/${game.getText('unit')}s/${parent2.slug}`}
                    >
                      <img
                        src={`https://${game.cdn}${parent2.img}`}
                        className='reverse-unit-img'
                      />
                      <div>{parent2.name}</div>
                    </StyledLink>
                  </div>
                </div>
              )
            })}
        </div>

        <h2>Shortest Path</h2>
        <div>Note: This is a work in progress</div>
        <div className='calculator-shortest'>
          <div className='calculator-unit'>
            <h2 className='capitalize'>Parent {game.getText('unit')}</h2>
            <UnitSelect
              value={shortUnit1}
              type='pals'
              units={units}
              onChange={(option) => {
                query.shortUnit1 = option.slug
                navigate({ search: qs.stringify(query) })
              }}
            />
          </div>
          <div className='calculator-unit'>
            <h2 className='capitalize'>Desired Child {game.getText('unit')}</h2>
            <UnitSelect
              value={shortUnit2}
              type='pals'
              units={units}
              onChange={(option) => {
                query.shortUnit2 = option.slug
                navigate({ search: qs.stringify(query) })
              }}
            />
          </div>
        </div>
        <button
          className='btn'
          onClick={() => {
            setShortest(findShortest(shortUnit1.id, shortUnit2.id))
          }}
          style={{ marginBottom: 15 }}
        >
          Find Shortest
        </button>
        {shortest.res && shortest.res.length > 0 ? (
          <div>
            {shortest.found.map((pairs, idx) => {
              return (
                <div style={{ display: 'flex' }}>
                  <Unit unit={unitsById[pairs[0]]} />
                  <Unit unit={unitsById[pairs[1]]} />
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 30,
                      margin: '0 15px',
                    }}
                  >
                    =
                  </span>
                  <Unit unit={unitsById[shortest.res[idx + 1]]} />
                </div>
              )
            })}
          </div>
        ) : (
          <div>No path found</div>
        )}
      </div>
      <AdSlot name='leaderboard' index={1} />
    </div>
  )
}

export default Calculator
