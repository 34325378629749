import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
    margin: 0;
    font-family: 'Roboto',
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .card {
    background-color: ${({ theme }) => theme.bodyAlt};
    border: ${({ theme }) => theme.border}
  }

  .card:hover {
    background-color: ${({ theme }) => theme.cardBackgroundHover};
  }

  .paper {
    background-color: ${({ theme }) => theme.cardBackground};
  }
`

export const lightTheme = {
  body: '#f7f7f7',
  text: '#292e32',
  headerText: '#888686',
  cardBackground: 'white',
  cardBackgroundHover: '#e9e9e9',
  cardBackgroundAlt: '#e9e9e9',
  cardBackgroundAltHover: '#e9e9e9',
  tableRowAlt: '#deedf6',
  toggleBorder: '#fff',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  bracketBackground: '#e7e7e7',
  bracketScoreBackground: '#D9D7D7',
  tableBorder: '1px solid lightgray',
}

export const darkTheme = {
  body: '#0d202b',
  bodyAlt: '#102531',
  bodyAltHover: '#122936',
  border: '1px solid #17313a',
  text: '#f5f5f5',
  headerText: '#888686',
  cardBackground: '#202224',
  cardBackgroundHover: '#434a51',
  cardBackgroundAlt: '#2d3744',
  cardBackgroundAltHover: '#2f3539',
  tableRowAlt: '#2d3744',
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
  bracketBackground: '#4e4e4e',
  bracketScoreBackground: '#292e32',
  tableBorder: '1px solid #878585',
  tagBtnBackground: '#0f2141',
  tagBtnHoverBackground: '#0f2141',
}
