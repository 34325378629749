import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import pagemeta from 'pagemeta'

import GameContext from '../contexts/GameContext'

const ServerHelmet = ({ data }) => {
  data = data || {}
  const { game } = useContext(GameContext)
  data.game = game.getTitle()
  data.site = game.site

  const location = useLocation()
  const config = pagemeta.get(location.pathname, data)

  if (!config) return null
  const { title, description } = config
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
    </Helmet>
  )
}

export default ServerHelmet
