import { youtubeRegex } from '../../services/util'

const YouTubeVideo = ({ link }) => {
  const videoId = link.match(youtubeRegex)
  if (!videoId[1]) return null

  return (
    <div class='video-container'>
      <iframe
        src={`https://www.youtube.com/embed/${videoId[1]}`}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowfullscreen
      ></iframe>
    </div>
  )
}

export default YouTubeVideo
