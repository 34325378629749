import { HiTrash } from 'react-icons/hi'

import FileUpload from '../inputs/FileUpload'
import UnitSelect from '../Units/UnitSelect'
import { StyledTextArea } from '../Styled'

import CreateUnits from './CreateUnits'

const CreateCompSteps = ({ steps, setSteps, allUnits, setAddingSteps }) => {
  return (
    <div>
      <div>
        {steps.map((step, idx) => {
          return (
            <div key={idx}>
              <div className='row'>
                <div className='col-xs-12 col-md-6 input-row login-input'>
                  <div className='input-label'>
                    Step {idx + 1}{' '}
                    <button
                      type='button'
                      className='btn-icon comp-step-trash'
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to delete this step?'
                          )
                        ) {
                          steps.splice(idx, 1)
                          setSteps([...steps])
                        }
                      }}
                    >
                      <HiTrash />
                    </button>
                  </div>
                  <div className='input-row login-input'>
                    <StyledTextArea
                      value={step.description}
                      onChange={(e) => {
                        steps[idx].description = e.target.value
                        setSteps([...steps])
                      }}
                    />
                  </div>
                  <div className='input-row login-input'>
                    <div className='input-label'>Materials</div>
                    <div style={{ marginBottom: 10 }}>
                      <UnitSelect
                        units={allUnits}
                        onChange={(unit) => {
                          const exUnit = step.units.find(
                            (u) => u.value === unit.value
                          )
                          // TODO: Make game feature UNITS:UNIQUE
                          if (!exUnit) {
                            steps[idx].units.push(unit)
                            setSteps([...steps])
                          }
                        }}
                      />
                    </div>
                    <div>
                      {step.units && (
                        <CreateUnits
                          units={step.units}
                          setUnits={(newUnits) => {
                            // steps[idx].units[unitIdx].amount = parseInt(
                            //   e.target.value
                            // )
                            // setSteps([...steps])
                            steps[idx].units = newUnits
                            setSteps([...steps])
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-md-6 input-row login-input'>
                  <div className='input-label'>Image</div>
                  <div>
                    <FileUpload
                      message={
                        step.imgs ? (
                          <img src={step.imgs[0]} alt='upload' />
                        ) : (
                          ''
                        )
                      }
                      handleUpload={(image, blob, fileName) => {
                        if (image) {
                          steps[idx].imgUrl = image
                          steps[idx].img = blob
                          steps[idx].imgFileName = fileName
                          setSteps([...steps])
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div>
        <button
          type='button'
          className='btn step-add-btn'
          onClick={() => {
            steps.push({
              description: '',
              imgUrl: '',
              img: '',
              units: [],
            })
            setSteps([...steps])
          }}
          style={{ marginRight: 10 }}
        >
          Add Step
        </button>
        <button
          className='btn-link'
          onClick={() => {
            if (
              window.confirm(
                'Are you sure you want to clear all of your steps?'
              )
            ) {
              setSteps([])
              setAddingSteps(false)
            }
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default CreateCompSteps
