import { useState, useContext, useEffect } from 'react'

import GameContext from '../contexts/GameContext'

const Image = (props) => {
  const { game } = useContext(GameContext)
  const [error, setError] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { errImg, icon } = props

  useEffect(() => {
    setHasError(false)
  }, [props.src])

  if (error) return icon

  return (
    <img
      {...props}
      alt={props.alt}
      onError={(e) => {
        if (!hasError) {
          setHasError(true)
          if (icon) setError(true)
          e.target.onError = null
          e.target.src =
            errImg || `https://${game.cdn}/${game.key}/icons/palsphere.svg`
        }
      }}
      style={{ height: '100%', objectFit: 'contain' }}
    />
  )
}

export default Image
