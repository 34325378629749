import { useState } from 'react'

import http from '../../services/http'
import TagSelect from './TagSelect'

const AddTags = ({ comp, unit, onAdding, onAdd }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (newOpen) => {
    if (onAdding) onAdding(newOpen)
    setOpen(newOpen)
  }

  return (
    <div>
      {!open && (
        <button
          className='comp-event-add-title btn'
          onClick={() => {
            toggleOpen(!open)
          }}
        >
          Add Tag
        </button>
      )}

      {open && (
        <div className='comp-event-add-title' style={{ display: 'flex' }}>
          <div style={{ marginRight: 10 }}>
            <TagSelect
              type={comp ? 'Comp' : 'Unit'}
              onChange={(currTag) => {
                if (comp) {
                  http.post('/api/tags/comps', {
                    tag: currTag.value,
                    comp: comp.id,
                  })
                } else if (unit) {
                  http.post('/api/tags/units', {
                    tag: currTag.value,
                    unit: unit.id,
                  })
                }
                if (onAdd) onAdd(currTag)
                if (onAdding) onAdding(false)
                setOpen(false)
              }}
            />
          </div>
          <button
            className='btn-alt2'
            onClick={() => {
              toggleOpen(!open)
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  )
}

export default AddTags
