import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  MapContainer,
  TileLayer,
  Marker,
  Circle,
  Popup,
  useMapEvents,
} from 'react-leaflet'

import L from 'leaflet'
import { can } from 'rbac'

import GameContext from '../../contexts/GameContext'
import UserContext from '../../contexts/UserContext'
import {
  getSources,
  getSourceUnits,
  trackSources,
  untrackSources,
} from '../../services/sources'
import { getCoord } from '../../services/map'

import Helmet from '../../components/Helmet'
// import CanvasMarker from '../../components/Map/CanvasMarker'
import Unit from '../../components/Units/Unit'
import UnitSelect from '../../components/Units/UnitSelect'
import { DefaultIcon } from '../../components/Map/MapInput'
import Filter from './Filter'
import CreateMap from './CreateMap'

import { defaultIcons } from './CreateSource'

import './style.css'

const PalIcon = new L.Icon({
  iconUrl:
    'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_04.png',
  iconSize: [50, 50],
})

L.Marker.prototype.options.icon = DefaultIcon

function LocationMarker({ source, setCurrSource, locIcons }) {
  useMapEvents({
    click: (e) => {
      // console.log(e)
      source.lat = e.latlng.lat
      source.lng = e.latlng.lng
      setCurrSource({ ...source })
    },
  })

  return source?.lat ? (
    source.radius > 0 ? (
      <Circle
        center={[source.lat, source.lng]}
        radius={source.radius}
        pathOptions={{ color: 'blue' }}
      />
    ) : (
      <Marker
        position={[source.lat, source.lng]}
        icon={
          source.imgs && locIcons[source.imgs[0]]
            ? locIcons[source.imgs[0]]
            : source.img && locIcons[source.img]
            ? locIcons[source.img]
            : PalIcon
        }
      >
        {/* <Popup>
          {source.name} : {source.lat}, {source.lng}
        </Popup> */}
      </Marker>
    )
  ) : null
}

const Map = () => {
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const params = useParams()
  const [map, setMap] = useState({})
  // For custom map
  const [sources, setSources] = useState([])
  // For units map
  const [locations, setLocations] = useState([])
  // For points map
  const [points, setPoints] = useState([])
  const [filters, setFilters] = useState({})
  const [locIcons, setLocIcons] = useState(defaultIcons)
  const [units, setUnits] = useState({})
  const [unit, setUnit] = useState({})

  const [currSource, setCurrSource] = useState({})

  const updateMap = (map) => {
    setMap(map)
    if (map?.value) navigate(`/map/${map.value}`)
  }

  useEffect(() => {
    if (units.length > 0) {
      const currUnit = units.find((u) => u.slug === (params.id || unit.slug))
      if (currUnit && isNaN(params.id) && !unit.slug) setUnit(currUnit)
      if (currUnit?.id || unit?.id)
        getSourceUnits({
          unit: unit?.id || currUnit?.id,
        }).then((res) => {
          setLocations(res.data)
          res.data.forEach((loc) => {
            if (loc.img && !locIcons[loc.img])
              locIcons[loc.img] = new L.Icon({
                iconUrl: 'https://' + game.cdn + loc.img,
                iconSize: [30, 30],
              })

            if (loc.imgs && !locIcons[loc.imgs[0]])
              locIcons[loc.imgs[0]] = new L.Icon({
                iconUrl: 'https://' + game.cdn + loc.imgs[0],
                iconSize: [50, 50],
              })

            setLocIcons({ ...locIcons })
          })
        })
    }
  }, [params.id, unit.id, units.length])

  useEffect(() => {
    if (map?.value)
      getSources({ map: map?.value }).then((res) => {
        setSources(res.data)
        res.data.forEach((loc) => {
          if (loc.img && !locIcons[loc.img])
            locIcons[loc.img] = new L.Icon({
              iconUrl: 'https://' + game.cdn + loc.img,
              iconSize: [30, 30],
            })
          if (loc.imgs && loc.imgs[0] && !locIcons[loc.imgs[0]])
            locIcons[loc.imgs[0]] = new L.Icon({
              iconUrl: 'https://' + game.cdn + loc.imgs[0],
              iconSize: [50, 50],
            })
          setLocIcons({ ...locIcons })
        })
      })
  }, [map?.value])

  useEffect(() => {
    getSources({ type: 'points', track: true }).then((res) => {
      setPoints(res.data)
      res.data.forEach((loc) => {
        if (loc.imgs && !locIcons[loc.imgs[0]])
          locIcons[loc.imgs[0]] = new L.Icon({
            iconUrl: 'https://' + game.cdn + loc.imgs[0],
            iconSize: [30, 30],
          })

        setLocIcons({ ...locIcons })
      })
    })
  }, [])

  const allSource = [...locations, ...sources, ...points]

  const categories = {}
  points.forEach((location) => {
    if (!categories[location.category]) categories[location.category] = 0
    // if (!categories[location.category][location.name])
    //   categories[location.category][location.name] = 0
    // categories[location.category][location.name]++
    categories[location.category]++
    if (filters[location.category] === undefined)
      filters[location.category] = false
  })

  return (
    <div className='container-fluid map-page'>
      <Helmet data={{ name: unit.name || map.label }} />
      <div className='row' style={{ margin: 0 }}>
        <div className='col-xs-12 col-sm-3'>
          <div style={{ marginBottom: 20 }}>
            <UnitSelect
              type='pals'
              onChange={(option) => {
                if (!map?.value) navigate(`/map/${option.slug}`)
                setUnit(option)
              }}
              onLoad={(data) => {
                setUnits(data)
              }}
              noValue
            />
          </div>
          <Unit unit={unit} link />
          <Filter
            map={map}
            categories={categories}
            filters={filters}
            setFilters={setFilters}
          />
          <CreateMap
            map={map}
            setMap={updateMap}
            source={currSource}
            setSource={setCurrSource}
            setUnit={setUnit}
            locations={locations}
            setLocations={setLocations}
          />
        </div>
        <div className='col-xs-12 col-sm-9' style={{ padding: 0 }}>
          <MapContainer
            center={[-128, 128]}
            zoom={1}
            minZoom={1}
            maxZoom={4}
            style={{ height: 'calc(100vh - 200px)', flex: 1 }}
            crs={L.CRS.Simple}
            preferCanvas
          >
            <TileLayer
              url='https://cdn.zeldabuilds.gg/palworld/map/main/{z}/{x}/{y}.png'
              noWrap
            />
            {/* <Marker
              position={[
                (256 / mapTotal) * (155200.88 - mapBounds.max.X),
                (256 / mapTotal) * (458640.1 - mapBounds.min.Y),
              ]}
            ></Marker> */}
            {/* <CanvasMarker markers={locations} locIcons={locIcons} />x */}
            {allSource
              .filter((source) => {
                return filters[source.category] !== undefined
                  ? filters[source.category]
                  : true
              })
              .map((location) => {
                // return null
                return location.radius > 0 ? (
                  <Circle
                    center={[location.lat, location.lng]}
                    radius={location.radius}
                    pathOptions={{ color: 'blue' }}
                  />
                ) : (
                  <Marker
                    position={
                      location.raw
                        ? [location.lat, location.lng]
                        : getCoord(location.lat, location.lng)
                    }
                    icon={
                      location.imgs && locIcons[location.imgs[0]]
                        ? locIcons[location.imgs[0]]
                        : location.img && locIcons[location.img]
                        ? locIcons[location.img]
                        : PalIcon
                    }
                    opacity={location.tracked ? 0.5 : 1}
                  >
                    {location.name && (
                      <Popup>
                        <span style={{ display: 'block', marginBottom: 5 }}>
                          {location.name}
                        </span>
                        {location.type === 'points' && (
                          <label>
                            <input
                              type='checkbox'
                              style={{ width: 'auto' }}
                              checked={location.tracked}
                              onChange={(e) => {
                                if (!user) return navigate('/signup')

                                if (e.target.checked) {
                                  trackSources({ source: location.id })
                                } else {
                                  untrackSources({ source: location.id })
                                }
                                const locIdx = points.findIndex(
                                  (p) => p.id + '' === location.id + ''
                                )
                                if (locIdx !== -1) {
                                  points[locIdx].tracked = e.target.checked
                                  setPoints([...points])
                                }
                              }}
                            />
                            Found
                          </label>
                        )}
                      </Popup>
                    )}
                  </Marker>
                )
              })}
            {(can(user, 'ADMIN:PANEL') || map?.value) && (
              <LocationMarker
                source={currSource}
                setCurrSource={setCurrSource}
                locIcons={locIcons}
              />
            )}
          </MapContainer>
        </div>
      </div>
    </div>
  )
}

export default Map
