import { useState, useEffect, useContext } from 'react'
import { can } from 'rbac'
import { useParams, useNavigate } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import Markdown from 'react-markdown'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../contexts/GameContext'
import http from '../../services/http'
import { youtubeRegex, tiktokRegex } from '../../services/util'

import { StyledLink } from '../../components/Styled'
import AdSlot from '../../components/Ads/AdSlot'
import Comps from '../../components/Comps'
import CompVote from '../../components/Comps/CompVote'
import Helmet from '../../components/Helmet'
import Ip from '../../components/Comps/Ip'
import Map from '../../components/Map'
import PalStats from '../../components/Comps/PalStats'
import Steps from '../../components/Steps'
import Tags from '../../components/Tags'
import TikTokVideo from '../../components/Comps/TikTokVideo'
import Units from '../../components/Units'
import YouTubeVideo from '../../components/Comps/YouTubeVideo'

import BuildActions from './BuildActions'

import './style.css'

const Build = () => {
  const [comp, setComp] = useState({})
  const [comps, setComps] = useState([])
  const params = useParams()
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)

  useEffect(() => {
    http
      .get('/api/comps', {
        id: params.id,
        source: true,
        steps: true,
        tags: true,
        unitProps: true,
        units: true,
        unitTags: true,
        user: true,
      })
      .then((res) => {
        if (res.error) return alert(res.error)
        setComp(res.data)

        http
          .get('/api/comps', {
            user_id: res.data.user_id,
            more: params.id,
            limit: 9,
          })
          .then((res) => {
            if (res.error) return alert(res.error)
            setComps(res.data)
          })
      })
  }, [params.id])

  const { name, description, imgs, units, sources } = comp

  return (
    <div className='home-page container-fluid'>
      <Helmet data={{ name }} />
      <div className='comp-info'>
        <div className='row'>
          <div className='col-xs-12 col-md-8 comp-image'>
            <div>
              <div className='comp-title-container'>
                <h1 className='comp-title'>{name}</h1>
                <CompVote
                  comp={comp}
                  onVote={(key, vote, voted) => {
                    comp.voted = vote
                    if (vote !== null) {
                      comp[key] += 1
                      if (voted !== null)
                        comp[key === 'upvotes' ? 'downvotes' : 'upvotes']--
                    } else {
                      comp[key] -= 1
                    }

                    setComps({ ...comp })
                  }}
                />
              </div>

              {comp.user && (
                <div className='comp-user'>
                  <StyledLink to={`/profile/${comp.user.id}`}>
                    by {comp.user.username}
                  </StyledLink>
                </div>
              )}
              {comp.ip && <Ip ip={comp.ip} />}
              {comp.tags && (
                <Tags tags={comp.tags} style={{ marginBottom: 10 }} />
              )}
              <div className='comp-description'>
                <Markdown>{description}</Markdown>
              </div>
            </div>
            {imgs && (
              <ImageGallery
                items={imgs
                  .sort((a, b) => {
                    if (a.match(youtubeRegex) && !b.match(youtubeRegex))
                      return -1
                    if (!a.match(youtubeRegex) && b.match(youtubeRegex))
                      return 1
                    if (a.match(tiktokRegex) && !b.match(tiktokRegex)) return -1
                    if (!a.match(tiktokRegex) && b.match(tiktokRegex)) return 1
                    return 0
                  })
                  .map((img, idx) => {
                    if (img.includes('youtube') || img.includes('youtu.be')) {
                      return {
                        renderItem: () => <YouTubeVideo link={img} />,
                      }
                    } else if (img.includes('tiktok')) {
                      return {
                        renderItem: () => (
                          <TikTokVideo
                            link={img}
                            bg={imgs.find(
                              (im) =>
                                !im.match(youtubeRegex) &&
                                !im.match(tiktokRegex)
                            )}
                            onLoad={(success) => {
                              if (!success) {
                                comp.imgs = comp.imgs.filter((ci) => ci !== img)
                                setComp({ ...comp })
                              }
                            }}
                          />
                        ),
                      }
                    }
                    return { original: `https://${game.cdn}/${img}` }
                  })}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay={false}
              />
            )}
            <PalStats units={units} />
            {sources && <Map sources={sources} />}
          </div>
          <div className='col-xs-12 col-md-4 comp-units'>
            {units && (
              <Units
                units={units}
                cost={
                  comp.tags && !comp.tags.find((tag) => tag.tag === 'Outfit')
                }
              />
            )}
            {(can(user, 'COMPS:APPROVE') ||
              (user && user.id === comp.user_id)) && (
              <BuildActions game={game} comp={comp} setComp={setComp} />
            )}
            <AdSlot name='med_rect_atf' />
          </div>
        </div>
      </div>
      {comp.steps && <Steps steps={comp.steps} />}
      {comp.user && comps.length > 0 && (
        <div>
          <div className='comp-more-title'>
            <span className='capitalize'>
              More {game.getText('build') + 's'}{' '}
            </span>
            by {comp.user.username}
          </div>
          <Comps comps={comps} setComps={setComps} />
        </div>
      )}
    </div>
  )
}

export default Build
