import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

import { getUnits } from '../../services/units'

import { StyledLink, StyledInput } from '../../components/Styled'
import GameContext from '../../contexts/GameContext'
import UserContext from '../../contexts/UserContext'

import AdSlot from '../../components/Ads/AdSlot'
import Helmet from '../../components/Helmet'
import ImageC from '../../components/Image'
import TagFilter from '../../components/Tags/TagFilter'

import UnitList from './UnitList'

import './style.css'

const UnitsPage = ({ type }) => {
  const [units, setUnits] = useState([])
  const [search, setSearch] = useState('')
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.substring(1))
  const hasNewTags = query.tags ? query.tags.length : 0

  useEffect(() => {
    getUnits({
      type: type || game.getText('unit') + 's',
      tagsFilter: query.tags,
      tags: true,
      catalogFilter: query.catalog,
    }).then((res) => {
      if (res.error) return alert(res.error)
      setUnits(res.data)
    })
  }, [hasNewTags, type, query.catalog])

  const unitType = type || game.getText('unit') + 's'

  return (
    <div className='container-fluid units-page'>
      <Helmet />
      <div className='row'>
        <div className='col-xs-0 col-md-3'>
          <TagFilter
            type={unitType}
            category=''
            value={query.tags}
            clearTags={() => navigate({ search: '' })}
            onChange={(newTag) => {
              if (query.tags && query.tags.includes(newTag.id + '')) {
                query.tags = query.tags.filter((t) => t !== newTag.id + '')
                navigate({ search: qs.stringify(query) })
              } else {
                if (!query.tags) query.tags = []
                query.tags.push(newTag.id + '')
                navigate({ search: qs.stringify(query) })
              }
            }}
            vertical
            sort
          />
          <label>
            <input
              type='checkbox'
              checked={query.catalog === 'true'}
              onChange={(e) => {
                if (!user) return navigate('/signup')
                if (e.target.checked) {
                  query.catalog = e.target.checked
                } else {
                  delete query.catalog
                }
                navigate({ search: qs.stringify(query) })
              }}
              style={{ width: 'auto' }}
            />
            Catalog
          </label>
          <AdSlot
            name='right-rail'
            mobile={false}
            containerStyle={{
              alignItems: 'flex-start',
              marginLeft: 10,
              position: 'sticky',
              top: 20,
              maxHeight: 650,
            }}
          />
        </div>
        <div className='col-xs-12 col-md-9'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className='capitalize' style={{ fontSize: 20 }}>
              {game.name} {unitType} List
            </h1>
            <div>
              <StyledInput
                className='search-bar'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                placeholder={`Search ${unitType}`}
              />
            </div>
          </div>
          {game.has('UNITS:LIST') ? (
            <UnitList
              units={units}
              search={search}
              unitType={unitType}
              sort={query.sort}
            />
          ) : (
            <div className='row'>
              {units
                .filter((u) => u.name.includes(search))
                .map((unit, unitIdx) => {
                  return (
                    <>
                      <div className='col-xs-6 col-sm-3'>
                        <div className='units-page-unit'>
                          <StyledLink to={`/${unitType}/${unit.id}`}>
                            <ImageC
                              src={`https://${game.cdn}${
                                unit.img || `/units/${unit.id}.png`
                              }`}
                              className='units-page-unit-img'
                            />
                            <div>
                              {unit.amount}{' '}
                              {unit.name + (unit.amount > 1 ? 's' : '')}
                            </div>
                          </StyledLink>
                        </div>
                      </div>
                      {window.innerWidth < 600 &&
                        unitIdx !== 0 &&
                        (unitIdx + 1) % 8 === 0 && (
                          <div className='col-xs-12'>
                            <AdSlot
                              name='leaderboard'
                              index={unitIdx}
                              mobile={true}
                            />
                          </div>
                        )}
                    </>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnitsPage
