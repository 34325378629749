import { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import GameContext from '../../contexts/GameContext'
import { getTags } from '../../services/tags'

import { StyledSelect } from '../Styled'
import Button from '../inputs/Button'

const sortOptions = [
  { label: 'Alphabetical', value: 'name' },
  {
    label: 'Paldeck Number',
    value: 'tag_Paldeck Number',
  },
]

const TagFilter = ({ type, onChange, clearTags, value, vertical, all }) => {
  const { game } = useContext(GameContext)
  const [tags, setTags] = useState([])
  const location = useLocation()
  const query = qs.parse(location.search.substring(1))
  const navigate = useNavigate()

  useEffect(() => {
    getTags({ type }).then((res) => {
      if (res.error) return alert(res.error)
      setTags(res.data)
    })
  }, [type])

  const categories = {}
  tags.forEach((tag) => {
    if (all ? true : tag.format?.filter) {
      if (!categories[tag.category]) categories[tag.category] = []
      categories[tag.category].push(tag)
    }
  })

  return (
    <div
      className='tag-filters'
      style={{
        display: vertical ? '' : 'flex',
      }}
    >
      <span className='tag-filters-header'>
        <h2 style={{ fontSize: '20px' }}>Filters</h2>
        <Button className='btn-alt3' onClick={clearTags}>
          Clear
        </Button>
      </span>
      <hr />
      <div className='tag-filters-container'>
        {Object.keys(categories).map((category) => {
          return (
            <div
              className='tag-filter-category'
              style={{
                marginRight: vertical ? '' : 15,
              }}
            >
              <div className='tag-filter-category-title'>{category}</div>
              {categories[category].map((tag) => {
                return (
                  <button
                    className={
                      'tag-filter' +
                      (value && value.includes(tag.id + '')
                        ? ' tag-filter-active'
                        : '')
                    }
                    key={tag.id}
                    onClick={() => {
                      onChange(tag)
                    }}
                  >
                    {tag.img ? (
                      <img
                        src={`https://${game.cdn}${tag.img}`}
                        className='tag-filter-img'
                      />
                    ) : (
                      tag.tag
                    )}
                  </button>
                )
              })}
            </div>
          )
        })}
        <div>
          <div
            className='tag-filter-category'
            style={{ marginRight: vertical ? '' : 15 }}
          >
            <div className='tag-filter-category-title'>Sort</div>
            <StyledSelect
              value={sortOptions.find((op) => op.value === query.sort)}
              onChange={(option) => {
                query.sort = option.value
                navigate({ search: qs.stringify(query) })
              }}
              options={sortOptions}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TagFilter
