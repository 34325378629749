import { useState, useContext } from 'react'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

import GameContext from '../../contexts/GameContext'

export const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
  iconSize: [25, 41],
  popupAnchor: [0, -46],
})

function LocationMarker({ source, setSource, onChange }) {
  useMapEvents({
    click: (e) => {
      //   console.log(e)
      source.lat = e.latlng.lat
      source.lng = e.latlng.lng
      setSource({ ...source })
      onChange(e.latlng.lat, e.latlng.lng)
    },
  })

  return <Marker position={[source.lat || 0, source.lng || 0]}></Marker>
}

const MapInput = ({ onChange }) => {
  const { game } = useContext(GameContext)
  const [source, setSource] = useState({})

  return (
    <MapContainer
      center={[-128, 128]}
      zoom={2}
      minZoom={1}
      maxZoom={4}
      zoomControl={false}
      style={{ height: 300 }}
      crs={L.CRS.Simple}
      preferCanvas
    >
      <TileLayer url='https://cdn.zeldabuilds.gg/palworld/map/main/{z}/{x}/{y}.png' />
      <LocationMarker
        source={source}
        setSource={setSource}
        onChange={onChange}
      />
    </MapContainer>
  )
}

export default MapInput
