import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AddEvent from '../../components/Events/AddEvent'
import AddTags from '../../components/Tags/AddTags'

const BuildActions = ({ game, comp, setComp }) => {
  const [adding, setAdding] = useState('')
  const navigate = useNavigate()

  return (
    <div className='build-actions'>
      <div style={{ marginRight: 10 }}>
        <button
          onClick={() => {
            navigate(`/build/${comp.id}/edit`, {
              state: { comp },
            })
          }}
          className='comp-event-add-title btn'
        >
          Edit
        </button>
      </div>
      {game.has('USES:ENABLED') && (!adding || adding === 'event') && (
        <div style={{ marginRight: 10 }}>
          <AddEvent
            comp={comp}
            onAdding={(open) => setAdding(open ? 'event' : '')}
          />
        </div>
      )}
      {(!adding || adding === 'tag') && (
        <AddTags
          comp={comp}
          onAdding={(open) => setAdding(open ? 'tag' : '')}
          onAdd={(newTag) => {
            if (!comp.tags) comp.tags = []
            comp.tags.push(newTag.data)
            setComp({ ...comp })
          }}
        />
      )}
    </div>
  )
}

export default BuildActions
