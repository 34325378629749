import { useState, useEffect, useContext } from 'react'

import { getEvents } from '../../services/events'

import GameContext from '../../contexts/GameContext'
import Helmet from '../../components/Helmet'
import ImageC from '../../components/Image'
import { StyledLink } from '../../components/Styled'

import './style.css'

const EventsPage = () => {
  const [events, setEvents] = useState([])
  const [spoilers, setSpoilers] = useState(true)
  const { game } = useContext(GameContext)

  useEffect(() => {
    getEvents().then((res) => {
      if (res.error) return alert(res.error)
      setEvents(res.data)
    })
  }, [])

  return (
    <div className='container-fluid events-page'>
      <Helmet />
      <div className='events-top-bar'>
        <label className='events-spoilers-filter'>
          <input
            type='checkbox'
            checked={spoilers}
            onChange={(e) => {
              setSpoilers(e.target.checked)
            }}
          />
          Spoilers
        </label>
      </div>
      <div className='row'>
        {events.map((event, eventIdx) => {
          return (
            <div className='col-xs-6 col-sm-3 col-md-2'>
              <div className='events-page-event'>
                {spoilers && event.spoiler ? (
                  <div
                    onClick={() => {
                      events[eventIdx].spoiler = false
                      setEvents([...events])
                    }}
                  >
                    <div className='events-page-spoiler'>
                      <div style={{ textAlign: 'center' }}>
                        <div>Spoiler</div>
                        <div>Click to Reveal</div>
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                ) : (
                  <StyledLink to={`/events/${event.id}`}>
                    <ImageC
                      src={`https://${game.cdn}${event.imgs ? event.imgs[0] : ''}`}
                      className='events-page-event-img'
                    />
                    <div>{event.name}</div>
                  </StyledLink>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EventsPage
