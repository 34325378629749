import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { can } from 'rbac'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../contexts/GameContext'
import http from '../../services/http'

import Helmet from '../../components/Helmet'
import Comps from '../../components/Comps'
import Sources from '../../components/Sources'
import Tags from '../../components/Tags'
import Map from '../../components/Map'
import SaveCatalog from '../../components/Units/SaveCatalog'

import UnitActions from './UnitActions'

import './style.css'

const Unit = () => {
  const [unit, setUnit] = useState({})
  const [comps, setComps] = useState([])
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)

  const params = useParams()

  useEffect(() => {
    const query = { slug: params.id, sources: true, tags: true }
    if (user) query.catalog = true
    http.get('/api/units', query).then((res) => {
      if (res.error) return alert(res.error)
      setUnit(res.data)

      http
        .get('/api/comps', {
          unitsFilter: [res.data.id],
          user: true,
          units: true,
          limit: 4,
        })
        .then((res) => {
          if (res.error) return alert(res.error)
          setComps(res.data)
        })
    })
  }, [])

  const banner = []
  const topTags = []
  const bottomTags = []
  if (unit.tags)
    unit.tags.forEach((tag) => {
      if (tag.format?.banner === 'left') {
        banner.push(tag)
      } else if (tag.format?.top) {
        topTags.push(tag)
      } else {
        bottomTags.push(tag)
      }
    })

  return (
    <div className='container-fluid unit-page'>
      <Helmet data={{ name: unit.name }} />
      <div className='unit-info'>
        <div className='unit-img'>
          <h1 className='unit-title'>
            {unit.name}
            {/* {game.has('BUILDS:NO_UNITS') ? '' : ' Builds'} */}
            <span>
              {banner &&
                banner.map((bann) => {
                  return bann.img ? (
                    <img
                      src={`https://${game.cdn}${bann.img}`}
                      className='unit-tag-banner'
                    />
                  ) : null
                })}
            </span>
          </h1>
          <img
            src={`https://${game.cdn}${unit.img}`}
            className='unit-page-img'
            style={{ marginBottom: 10 }}
          />
          <SaveCatalog unit={unit} setUnit={setUnit} />
        </div>
        <div style={{ flex: 1 }}>
          {unit.tags && (
            <Tags tags={topTags} style={{ marginBottom: 10 }}>
              {unit.sources && (
                <div className='col-xs-12 col-md-6'>
                  <Map sources={unit.sources} />
                </div>
              )}
            </Tags>
          )}
          <div>{unit.description}</div>
          {/* {can(user, 'COMPS:APPROVE') && (
            <UnitActions unit={unit} setUnit={setUnit} />
          )} */}
          {/* {unit.sources && <Sources sources={unit.sources} />} */}
        </div>
      </div>
      {unit.tags && <Tags tags={bottomTags} style={{ marginBottom: 10 }} />}
      <hr />
      <h2>Possible Builds with {unit.name}</h2>
      {comps.length === 0 ? (
        'No builds'
      ) : (
        <Comps comps={comps} setComps={setComps} />
      )}
    </div>
  )
}

export default Unit
