export class Graph {
  constructor() {
    this.vertices = {}
  }

  addVertex(vertex) {
    this.vertices[vertex] = []
  }

  addEdge(vertex1, vertex2) {
    this.vertices[vertex1].push(vertex2)
    this.vertices[vertex2].push(vertex1)
  }

  shortestPath(start, end) {
    const queue = [start]
    const visited = {}
    const predecessor = {}

    visited[start] = true
    predecessor[start] = null

    while (queue.length > 0) {
      const currentVertex = queue.shift()

      if (currentVertex === end) {
        // Reconstruct the path from end to start
        const path = []
        let vertex = end
        while (vertex !== null) {
          path.unshift(vertex)
          vertex = predecessor[vertex]
        }
        return path
      }

      const neighbors = this.vertices[currentVertex]

      for (const neighbor of neighbors) {
        if (!visited[neighbor]) {
          visited[neighbor] = true
          predecessor[neighbor] = currentVertex
          queue.push(neighbor)
        }
      }
    }

    // If no path is found
    return null
  }
}
