import { useContext } from 'react'
import { HiTrash } from 'react-icons/hi'

import GameContext from '../../contexts/GameContext'

import { StyledInput, StyledSelect } from '../Styled'
import ImageComp from '../Image'

const CreateUnits = ({ values, units, setUnits }) => {
  const { game } = useContext(GameContext)
  return (
    <div className='comp-create-grid'>
      {units.map((unit, idx) => {
        const empty = !unit || Object.keys(unit).length === 0
        return (
          <div className='comp-create-grid-unit' key={idx}>
            <div className='comp-create-grid-top'>
              <ImageComp
                src={`https://${game.cdn}${
                  unit.img || `/units/${unit.value}.png`
                }`}
                className='create-grid-unit-img'
              />
              <div className='comp-create-grid-label'>{unit.label}</div>
            </div>
            <div className='comp-create-grid-bot'>
              {!game.has('BUILDS:NO_AMOUNT') && (
                <StyledInput
                  value={unit.amount || ''}
                  onChange={(e) => {
                    units[idx].amount = parseInt(e.target.value)
                    setUnits([...units])
                  }}
                  placeholder='amount'
                  className='comp-create-amount'
                />
              )}
              {!empty && (
                <button
                  type='button'
                  className='btn-icon comp-step-trash'
                  onClick={() => {
                    if (values.type?.slots) {
                      units[idx] = {}
                    } else {
                      units.splice(idx, 1)
                    }
                    setUnits([...units])
                  }}
                >
                  <HiTrash />
                </button>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CreateUnits
