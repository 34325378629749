import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import { getMaps, createMap } from '../../services/map'

import { StyledSelect, StyledInput } from '../../components/Styled'

import CreateSource from './CreateSource'

const CreateMap = ({
  map,
  setMap,
  source,
  setSource,
  setUnit,
  locations,
  setLocations,
}) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const params = useParams()

  const [creating, setCreating] = useState(false)
  const [maps, setMaps] = useState([])
  const [name, setName] = useState('')

  useEffect(() => {
    if (user || !isNaN(params.id)) {
      const query = {}
      if (params.id && !isNaN(params.id)) query.id = params.id
      if (user) query.user = user.id
      getMaps(query).then((res) => {
        if (res.data[0]) {
          setMaps(res.data)
        } else {
          setMaps([res.data])
        }
        if (params.id) {
          if (user) {
            const paramMap = res.data.find((m) => m.id === params.id)
            if (paramMap) setMap({ label: paramMap.name, value: paramMap.id })
          } else {
            setMap({ label: res.data.name, value: res.data.id })
          }
        }
      })
    }
  }, [])

  const mapOptions = [{ label: 'Create New +', value: 'new' }]
  maps.forEach((m) => {
    mapOptions.push({ label: m.name, value: m.id })
  })

  return (
    <div>
      <h2>Custom Map</h2>
      <StyledSelect
        value={map}
        options={mapOptions}
        onChange={(option) => {
          if (option.value === 'new') {
            if (user) {
              setCreating(true)
            } else {
              navigate('/signup')
            }
          } else {
            setMap(option)
          }
        }}
        placeholder='Choose map...'
      />
      {creating && (
        <div style={{ marginTop: 10 }}>
          <div className='input-row'>
            <div className='input-label'>Map Name</div>
            <StyledInput
              placeholder='Name'
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </div>
          <div className='btn-bar'>
            <button
              className='btn-alt'
              onClick={() => {
                setCreating(false)
              }}
            >
              Cancel
            </button>
            <button
              className='btn'
              onClick={() => {
                setName('')
                createMap({ name }).then((res) => {
                  if (!res.data) return
                  const newMap = { label: res.data.name, value: res.data.id }
                  setMap(newMap)
                  setMaps([newMap, ...maps])
                })
                setCreating(false)
              }}
            >
              Create
            </button>
          </div>
        </div>
      )}
      {source.lat ? (
        <CreateSource
          source={source}
          setSource={setSource}
          map={map}
          locations={locations}
          setLocations={setLocations}
        />
      ) : (
        map?.value && (
          <div style={{ marginTop: 10 }}>Click on the map to add a point</div>
        )
      )}
    </div>
  )
}

export default CreateMap
