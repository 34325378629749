import { useState, useContext, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate, Link } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../contexts/GameContext'
import http from '../../services/http'
import { youtubeRegex, tiktokRegex } from '../../services/util'

import { StyledInput, StyledTextArea, StyledSelect } from '../Styled'
import Button from '../inputs/Button'
import FileUpload from '../inputs/FileUpload'
import Helmet from '../Helmet'
import MapInput from '../Map/MapInput'
import UnitSelect from '../Units/UnitSelect'

import CreateCompSteps from './CreateCompSteps'
import CreateUnits from './CreateUnits'
import CreateUnitsGrid from './CreateUnitsGrid'
import PalStats from './PalStats'

import './style.css'

const optional = [
  'description',
  'imgUrl',
  'youtube',
  'max_players',
  'ip',
  'location',
]

const CompCreate = ({ comp, setStages, setMatches, onSubmit }) => {
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)
  const [units, setUnits] = useState(comp ? comp.units || [] : [])
  const [steps, setSteps] = useState(comp ? comp.steps || [] : [])
  const [allUnits, setAllUnits] = useState([])
  const [addingSteps, setAddingSteps] = useState(comp ? comp.steps : false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!user) navigate('/signup')
  })

  let oldImg = ''
  let oldYt = ''
  if (comp && comp.imgs) {
    comp.imgs.forEach((cImg) => {
      const isYouTube = cImg.match(youtubeRegex)
      const isTikTok = cImg.match(tiktokRegex)
      if (isYouTube || isTikTok) {
        oldYt = cImg
      } else {
        oldImg = cImg
      }
    })
  }

  return (
    <div className={comp ? '' : 'container-fluid'}>
      <Helmet />
      <div className='comps-page'>
        <h1 className='capitalize'>
          {comp ? 'Edit' : 'Create'} {game.getText('build')}
        </h1>
        <div className='comps-page-content'>
          <div className='comps-page-form'>
            <Formik
              initialValues={{
                name: comp ? comp.name : '',
                description: comp ? comp.description : '',
                imgUrl: comp ? `https://${game.cdn}/${oldImg}` : undefined,
                youtube: oldYt,
                location: comp?.source
                  ? { lat: comp.sources[0]?.lat, lng: comp.sources[1].lng }
                  : {},
                // max_players: comp ? comp.max_players : '',
                // ip: comp ? comp.ip : '',
              }}
              validate={(values) => {
                const errors = {}
                Object.keys(values).forEach((key) => {
                  if (!values[key] && !optional.includes(key))
                    errors[key] = 'Required'
                })
                if (values.youtube) {
                  const isYouTube = values.youtube.match(youtubeRegex)
                  const isTikTok = values.youtube.match(tiktokRegex)
                  if (!isYouTube && !isTikTok) {
                    errors['youtube'] = 'Invalid video link'
                  }
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)

                const {
                  description,
                  img,
                  imgFileName,
                  ip,
                  location,
                  max_players,
                  name,
                  type,
                  youtube,
                } = values
                const fd = new FormData()

                // TODO cover image edit
                // TODO multiple images
                if (!comp && img) fd.append('image', img, imgFileName)

                const payload = {}
                const oldcomp = comp || {}
                if (comp) payload.comp = comp.id
                if (name !== oldcomp.name) payload.name = name
                if (description !== oldcomp.description)
                  payload.description = description
                if (imgFileName) payload.imgFileName = imgFileName
                if (youtube !== oldYt) payload.youtube = youtube
                if (max_players !== oldcomp.max_players)
                  payload.max_players = max_players
                if (ip !== oldcomp.ip) payload.ip = ip
                if (type && type.value !== oldcomp.type)
                  payload.type = type.value
                if (location && location !== oldcomp.location)
                  payload.location = location

                console.log(type)

                if (units.length > 0) payload.units = []
                units.forEach((unit) => {
                  // Remove empty placeholders
                  if (unit.id)
                    payload.units.push({
                      amount: unit.amount,
                      unit_id: unit.id,
                      properties: unit.propValues,
                    })
                })

                if (payload.units?.length < type?.minUnits) {
                  setSubmitting(false)
                  return alert(
                    `You must include at least ${type.minUnits} ${game.getText(
                      'unit'
                    )}s in your build`
                  )
                }

                if (steps.length > 0) {
                  payload.steps = steps.map((step, idx) => {
                    if (step.img)
                      fd.append('image', step.img, imgFileName + idx)

                    return {
                      index: idx,
                      description: step.description,
                      imgFileName: imgFileName + idx,
                      units: step.units.map((unit) => {
                        return {
                          amount: unit.amount,
                          unit_id: unit.id,
                          properties: unit.propValues,
                        }
                      }),
                    }
                  })
                }

                fd.append('body', JSON.stringify(payload))

                http
                  .blob('/api/comps', fd, {}, comp ? 'PUT' : 'POST')
                  .then((res) => {
                    setSubmitting(false)
                    if (res.error) return alert(res.error)
                    if (onSubmit) {
                      onSubmit()
                    } else {
                      navigate(`/builds/submitted`)
                    }
                  })
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  {!values.octane && (
                    <>
                      <div className='input-row login-input'>
                        <div className='input-label create-comp-header capitalize'>
                          Type
                        </div>
                        <StyledSelect
                          value={values.type}
                          onChange={(option) => {
                            setFieldValue('type', option)
                            if (option.slots) {
                              const temp = []
                              for (let i = 0; i < option.slots; i++) {
                                temp.push({})
                              }
                              setUnits(temp)
                            }
                          }}
                          options={[
                            {
                              label: 'Base Build',
                              value: 'team',
                              slots: 15,
                              minUnits: 3,
                            },
                            {
                              label: 'Party Build',
                              value: 'party',
                              slots: 5,
                              minUnits: 5,
                            },
                          ]}
                        />
                        <ErrorMessage
                          name='compType'
                          component='div'
                          className='error'
                        />
                      </div>
                      <div className='input-row login-input'>
                        <div className='input-label create-comp-header capitalize'>
                          Name
                        </div>
                        <Field name='name' as={StyledInput} />
                        <ErrorMessage
                          name='name'
                          component='div'
                          className='error'
                        />
                      </div>
                      {!game.has('BUILDS:NO_IMG') && (
                        <div className='input-row login-input'>
                          <div className='input-label'>Cover Image</div>
                          <div style={{ maxWidth: 600 }}>
                            <FileUpload
                              message={
                                values.imgUrl ? (
                                  <img src={values.imgUrl} alt='upload' />
                                ) : (
                                  ''
                                )
                              }
                              handleUpload={(image, blob, fileName) => {
                                if (image) {
                                  setFieldValue('imgUrl', image)
                                  setFieldValue('img', blob)
                                  setFieldValue('imgFileName', fileName)
                                }
                              }}
                            />
                            <ErrorMessage
                              name='imgUrl'
                              component='div'
                              className='error'
                            />
                          </div>
                        </div>
                      )}
                      {!game.has('BUILDS:NO_VIDEO') && (
                        <div className='input-row login-input'>
                          <div className='input-label'>
                            YouTube/TikTok Video (Optional)
                          </div>
                          <Field name='youtube' as={StyledInput} />
                          <ErrorMessage
                            name='youtube'
                            component='div'
                            className='error'
                          />
                        </div>
                      )}
                      <div className='input-row login-input'>
                        <div className='input-label'>
                          Description
                          {!game.has('BUILDS:DESCRIPTION') ? ' (Optional)' : ''}
                        </div>
                        <Field name='description' as={StyledTextArea} />
                        <ErrorMessage
                          name='description'
                          component='div'
                          className='error'
                        />
                      </div>
                      {!addingSteps && (
                        <div className='input-row login-input'>
                          <div className='input-label capitalize'>
                            {game.getText('unit')}s
                          </div>
                          <div style={{ marginBottom: 10 }}>
                            <UnitSelect
                              type={game.getText('unit') + 's'}
                              onChange={(unit) => {
                                // const exUnit = units.find(
                                //   (u) => u.value === unit.value
                                // )
                                // TODO: Make game feature UNITS:UNIQUE
                                // if (!exUnit) {
                                if (values.type?.slots) {
                                  // Find open slot
                                  const idx = units.findIndex((u) => !u.label)
                                  if (idx > values.type.slots)
                                    return alert(
                                      "You've reached the maximum number of Pals"
                                    )
                                  if (idx !== -1) units[idx] = unit
                                } else {
                                  units.push(unit)
                                }
                                setUnits([...units])
                                // }
                              }}
                              onLoad={setAllUnits}
                            />
                          </div>
                          {game.has('UNITS:GRID') ? (
                            <CreateUnitsGrid
                              units={units}
                              setUnits={setUnits}
                              values={values}
                            />
                          ) : (
                            <CreateUnits units={units} setUnits={setUnits} />
                          )}
                          {!game.has('BUILDS:NO_STEPS') && (
                            <button
                              type='button'
                              className='btn'
                              onClick={() => {
                                setUnits([])
                                setSteps([
                                  {
                                    description: '',
                                    imgUrl: '',
                                    img: '',
                                    units: [],
                                  },
                                ])
                                setAddingSteps(true)
                              }}
                            >
                              Add Detailed Steps
                            </button>
                          )}
                        </div>
                      )}
                      {addingSteps && (
                        <div className='input-row login-input'>
                          <div
                            className='input-label create-comp-header'
                            style={{ marginBottom: 10 }}
                          >
                            Steps
                          </div>
                          <CreateCompSteps
                            steps={steps}
                            setSteps={setSteps}
                            allUnits={allUnits}
                            setAddingSteps={setAddingSteps}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {game.has('BUILDS:PLAYER_COUNT') && (
                    <>
                      <div className='input-row login-input'>
                        <div className='input-label'>Max Players</div>
                        <Field name='max_players' as={StyledInput} />
                        <ErrorMessage
                          name='max_players'
                          component='div'
                          className='error'
                        />
                      </div>
                      <div className='input-row login-input'>
                        <div className='input-label'>IP</div>
                        <Field name='ip' as={StyledInput} />
                        <ErrorMessage
                          name='ip'
                          component='div'
                          className='error'
                        />
                      </div>
                    </>
                  )}
                  {values.type?.value === 'team' && (
                    <div className='input-row login-input'>
                      <div className='input-label'>Location</div>
                      <MapInput
                        onChange={(lat, lng) => {
                          setFieldValue('location', { lat, lng })
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      className='btn comp-create-btn'
                      type='submit'
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      style={{ marginRight: 10 }}
                    >
                      {comp ? 'Save' : 'Create'}
                    </Button>
                    {!comp && (
                      <Link to='/' className='btn-link' disabled={isSubmitting}>
                        Cancel
                      </Link>
                    )}
                    {comp && (
                      <Button
                        type='button'
                        className='btn-alt'
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'Are you sure you want to delete this comp?'
                            )
                          )
                            return http
                              .del('/api/comps', { comp: comp.id })
                              .then((res) => {
                                if (res.error) return alert(res.error)
                              })
                        }}
                      >
                        Delete comp
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <PalStats units={units} />
        </div>
      </div>
    </div>
  )
}

export default CompCreate
