import { useState, useEffect } from 'react'

import { getTags } from '../../services/tags'
import { sortStr } from '../../services/util'

import { StyledSelect } from '../Styled'

const TagSelect = ({
  category,
  onChange,
  onLoad,
  placeholder,
  tags,
  type,
  value,
}) => {
  const [dbTags, setDbTags] = useState([])

  useEffect(() => {
    if (tags) {
      setDbTags(tags.sort((a, b) => sortStr(a, b, 'tag')))
    } else {
      getTags({ type, category }).then((res) => {
        if (res.error) return alert(res.error)
        setDbTags(res.data)
        if (onLoad) onLoad(res.data)
      })
    }
  }, [])

  const options = dbTags.map((tag) => {
    return {
      label: tag.tag,
      value: tag.id,
      data: tag,
    }
  })

  return (
    <StyledSelect
      value={options.find((option) =>
        value ? option.value + '' === value.id + '' : ''
      )}
      onChange={onChange}
      placeholder={placeholder || 'Choose a tag...'}
      options={options}
    />
  )
}

export default TagSelect
