import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'

import Comps from '../../components/Comps'
import Helmet from '../../components/Helmet'

import UserLikes from './UserLikes'

const Profile = () => {
  const params = useParams()
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [currUser, setCurrUser] = useState({})
  const [comps, setComps] = useState([])

  useEffect(() => {
    http.get('/api/users', { id: params.id }).then((res) => {
      if (res.error) return alert(res.error)
      setCurrUser(res.users)
    })

    http.get('/api/comps', { user_id: params.id, units: true }).then((res) => {
      if (res.error) return alert(res.error)
      setComps(res.data)
    })
  }, [])

  return (
    <div className='home-page container-fluid'>
      <Helmet data={{ username: currUser.username }} />
      <h1>{currUser.username}'s Builds</h1>
      {comps.length === 0 ? (
        <div>No builds</div>
      ) : (
        <Comps comps={comps} setComps={setComps} user={currUser} />
      )}
      {user && currUser.id === user.id && <UserLikes />}
    </div>
  )
}

export default Profile
