import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import Button from '../inputs/Button'

import { createCatalog, deleteCatalog } from '../../services/catalog'

const SaveCatalog = ({ unit, setUnit }) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => {
        if (!user) return navigate('/signup')
        if (unit.catalog) {
          return deleteCatalog({ unit: unit.id }).then(() => {
            setUnit({ ...unit, catalog: false })
          })
        } else {
          return createCatalog({ unit: unit.id }).then(() => {
            setUnit({ ...unit, catalog: true })
          })
        }
      }}
      className={unit.catalog ? 'btn-alt' : ''}
    >
      {unit.catalog ? 'Remove from' : 'Add to'} Catalog
    </Button>
  )
}

export default SaveCatalog
