import http from './http'

// export const getMaps = (query) => {
//   return http.get('/api/maps', query)
// }

export const createCatalog = (body) => {
  return http.post('/api/catalog', body)
}

export const deleteCatalog = (query) => {
  return http.del('/api/catalog', query)
}
