import { useState, useEffect, useContext } from 'react'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'

import Comps from '../../components/Comps'

const UserLikes = () => {
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [comps, setComps] = useState([])

  useEffect(() => {
    http.get('/api/comps/votes', { likes: user.id }).then((res) => {
      if (res.error) return alert(res.error)
      setComps(res.data)
    })
  }, [])

  return (
    <div>
      <h2>Likes</h2>
      {comps.length === 0 ? <div>No builds</div> : <Comps comps={comps} />}
    </div>
  )
}

export default UserLikes
