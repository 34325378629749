import { useState, useEffect, useContext } from 'react'
import { components } from 'react-select'

import GameContext from '../../contexts/GameContext'
import { getUnits } from '../../services/units'
import { sortStr } from '../../services/util'

import { StyledSelect } from '../Styled'

const Option = (props) => {
  const { data } = props
  const { img } = data
  return (
    <components.Option {...props}>
      <div className='option-item'>
        {img && (
          <img
            className='option-img'
            src={'https://cdn.zeldabuilds.gg' + img}
            alt='brand-img'
          />
        )}
        {' ' + data.label}
      </div>
    </components.Option>
  )
}

const UnitSelect = ({
  value,
  type,
  units,
  onChange,
  onLoad,
  placeholder,
  noValue,
}) => {
  const { game } = useContext(GameContext)
  const [dbUnits, setDbUnits] = useState([])

  useEffect(() => {
    if (units) {
      setDbUnits(units.sort((a, b) => sortStr(a, b, 'name')))
    } else {
      getUnits({ type, tags: true, properties: true }).then((res) => {
        if (res.error) return alert(res.error)
        setDbUnits(res.data)
        if (onLoad) onLoad(res.data)
      })
    }
  }, [units?.length])

  const options = dbUnits.map((unit) => {
    return {
      ...unit,
      label: unit.name,
      value: unit.id,
    }
  })

  return (
    <StyledSelect
      value={
        noValue
          ? ''
          : options.find((option) =>
              value ? option.value + '' === value.id + '' : ''
            )
      }
      onChange={onChange}
      placeholder={placeholder || `Search a ${game.getText('unit')}...`}
      options={options}
      components={{ Option }}
    />
  )
}

export default UnitSelect
