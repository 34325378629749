import { useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FaDiscord } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

import GameContext from '../../contexts/GameContext'
import http from '../../services/http'
import { CAPTCHA_KEY, setJWT, setUser } from '../../services/users'

const validateUsername = (username) => {
  return /^[\w\d \\.]{3,15}$/.test(username)
}

const verifyPassword = (pw) => {
  return /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/.test(pw)
}

export const discordUrl =
  process.env.NODE_ENV === 'production'
    ? `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_SITE}%2Flogin&response_type=code&scope=identify%20email%20guilds.join&prompt=none`
    : `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&response_type=code&scope=identify%20email%20guilds.join&prompt=none`

const Signup = () => {
  const { game } = useContext(GameContext)

  let captchaRef
  return (
    <div className='login'>
      <Helmet>
        <title>Sign Up | {game.site}</title>
        <meta name='description' content='Sign up for Booked' />
      </Helmet>
      <div className='container'>
        <h2>Create Account</h2>
        <a
          href={discordUrl.replace('rle.gg', game.site)}
          className='discord-login'
        >
          <FaDiscord style={{ marginRight: 5 }} />
          Sign Up with Discord
        </a>
        <div className='or'>
          <div className='or-line'></div>
          <span>or</span>
          <div className='or-line'></div>
        </div>
        <Formik
          initialValues={{
            username: '',
            password: '',
            password2: '',
            email: '',
            captcha: '',
          }}
          validate={(values) => {
            const errors = {}
            if (!values.username) errors.username = 'Required'
            if (!values.email) errors.email = 'Required'
            if (!values.password) errors.password = 'Required'
            if (!values.password2) errors.password2 = 'Required'
            if (values.password2 !== values.password)
              errors.password2 = 'Passwords do not match'

            if (values.username && !validateUsername(values.username)) {
              errors.username = 'Invalid username'
            }
            if (
              process.env.NODE_ENV !== 'development' &&
              values.password &&
              !verifyPassword(values.password)
            ) {
              errors.password =
                'Your password must be at least 8 characters and contain at least 1 capital letter, 1 small letter, and 1 number'
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            http.post(`/api/register`, values).then((res) => {
              setSubmitting(false)
              captchaRef.reset()
              if (res.error) return alert(res.error)
              setJWT(res.jwt)
              setUser(res.user)
              window.location = '/'
              // navigate('/checkemail')
            })
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Username<span className='error'>*</span>
                </div>
                <Field type='text' name='username' />
                <ErrorMessage
                  name='username'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Password<span className='error'>*</span>
                </div>
                <Field type='password' name='password' />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Confirm password<span className='error'>*</span>
                </div>
                <Field type='password' name='password2' />
                <ErrorMessage
                  name='password2'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row login-input'>
                <div className='input-label'>
                  Email<span className='error'>*</span>
                </div>
                <Field type='email' name='email' />
                <ErrorMessage name='email' component='div' className='error' />
              </div>
              <ReCAPTCHA
                sitekey={CAPTCHA_KEY}
                onChange={(e) => {
                  setFieldValue('captcha', e)
                }}
                ref={(el) => {
                  captchaRef = el
                }}
              />
              <div style={{ color: 'gray', marginTop: 15, marginBottom: 15 }}>
                By pressing register you agree to our{' '}
                <Link
                  style={{ color: 'unset' }}
                  to={'/termsofservice'}
                  target='blank'
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  style={{ color: 'unset' }}
                  to={'/privacypolicy'}
                  target='blank'
                >
                  Privacy Policy
                </Link>
              </div>
              <div className='login-btn-bar' style={{ marginTop: 10 }}>
                <button className='btn' type='submit' disabled={isSubmitting}>
                  Register
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='login-switch-container'>
          Already have an account?{' '}
          <Link to='/login' style={{ color: 'unset' }}>
            Log In
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Signup
