import { useState, useEffect } from 'react'

import { getEvents } from '../../services/events'
import { sortStr } from '../../services/util'

import { StyledSelect } from '../Styled'

const EventSelect = ({ value, events, onChange, onLoad, placeholder }) => {
  const [dbEvents, setDbEvents] = useState([])

  useEffect(() => {
    if (events) {
      setDbEvents(events.sort((a, b) => sortStr(a, b, 'name')))
    } else {
      getEvents().then((res) => {
        if (res.error) return alert(res.error)
        setDbEvents(res.data)
        if (onLoad) onLoad(res.data)
      })
    }
  }, [])

  const options = dbEvents.map((event) => {
    return {
      label: event.name,
      value: event.id,
      data: event,
    }
  })

  return (
    <StyledSelect
      value={options.find((option) =>
        value ? option.value + '' === value.id + '' : ''
      )}
      onChange={onChange}
      placeholder={placeholder || 'Choose a event...'}
      options={options}
    />
  )
}

export default EventSelect
