import { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

import GameContext from '../../contexts/GameContext'
import Unit from '../../components/Units/Unit'
import UnitSelect from '../../components/Units/UnitSelect'
import TagSelect from '../../components/Tags/TagSelect'

import { StyledSelect, StyledInput } from '../../components/Styled'

const flatten = (build) => {
  return btoa(JSON.stringify(build))
}

const handleFocus = (event) => event.target.select()

const soulOptions = [
  { label: 'Lv. 0', value: 0, bonus: 0 },
  { label: 'Lv. 1 - 1 Small Soul / 3%', value: 1, bonus: 3 },
  { label: 'Lv. 2 - 2 Small / 6%', value: 2, bonus: 6 },
  { label: 'Lv. 3 - 3 Small / 9%', value: 3, bonus: 9 },
  { label: 'Lv. 4 - 4 Small / 12%', value: 4, bonus: 12 },
  { label: 'Lv. 5 - 1 Medium / 15%', value: 5, bonus: 15 },
  { label: 'Lv. 6 - 2 Medium / 18%', value: 6, bonus: 18 },
  { label: 'Lv. 7 - 3 Medium / 21%', value: 7, bonus: 21 },
  { label: 'Lv. 8 - 1 Large / 24%', value: 8, bonus: 24 },
  { label: 'Lv. 9 - 2 Large / 27%', value: 9, bonus: 27 },
  { label: 'Lv. 10 - 3 Large / 30%', value: 10, bonus: 30 },
]

const passiveSkills = []

const starOptions = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
]

// Health = FLOOR(FLOOR(500 + 5*Level +HP_Stat * 0.5 *Level * (1 + HP_IV%)) * (1 + HP_Bonus%) * (1+HP_SoulsBonus%) * (1+CondenserBonus%))
// Attack = FLOOR(FLOOR(100 + Attack_Stat * .075 * Level * (1 + Attack_IV%)) * (1 + Attack_Bonus%) * (1 + Attack_SoulBonus%) * (1 + CondenserBonus%))
// Defense = FLOOR(FLOOR(50 + Defense_Stat * 0.075 *LEVEL * (1 + Defense_IV%)) * (1 + Defense_Bonus%) * (1 + Defense_SoulBonus%) * (1 + CondenserBonus%))

const getSkillsBonus = (skills, key) => {
  const bonus = {
    HP: 0,
    Attack: 0,
    Defense: 0,
  }
  skills.forEach((skill) => {
    const { data } = skill
    for (let i = 1; i < 4; i++) {
      if (data['TargetType' + i] && data['TargetType' + i] === 'ToSelf') {
        const value = data['EffectValue' + i]
        if (data['EffectType' + i] === 'Defense') {
          bonus['Defense'] += value
        } else if (
          data['EffectType' + i] === 'ShotAttack' ||
          data['EffectType' + i] === 'MeleeAttack'
        ) {
          bonus['Attack'] += value
        }
      }
    }
  })
  return bonus[key] / 100
}

const stats = [
  {
    key: 'HP',
    get: (unit, build, soul, skills) => {
      if (!unit || !build) return 0
      const key = 'HP'
      const tag = unit.tags.find((t) => t.tag === key)
      const level = build.level || 1
      const iv = (build.iv ? build.iv[key] || 0 : 0) / 100
      const souls = (soul?.bonus || 0) / 100
      const stars = build.stars || 0
      const HP_Bonus = getSkillsBonus(skills, key)
      return Math.floor(
        Math.floor(500 + 5 * level + tag.number * 0.5 * level * (1 + iv)) *
          (1 + HP_Bonus) *
          (1 + souls) *
          (1 + stars * 0.05)
      )
    },
  },
  {
    key: 'Attack',
    get: (unit, build, soul, skills) => {
      if (!unit || !build) return 0
      const key = 'Attack'
      const tag = unit.tags.find((t) => t.tag === 'MeleeAttack')
      const level = build.level || 1
      const iv = (build.iv ? build.iv[key] || 0 : 0) / 100
      const souls = (soul?.bonus || 0) / 100
      const stars = build.stars || 0
      const Attack_Bonus = getSkillsBonus(skills, key)
      return Math.floor(
        Math.floor(100 + tag.number * 0.075 * level * (1 + iv)) *
          (1 + Attack_Bonus) *
          (1 + souls) *
          (1 + stars * 0.05)
      )
    },
  },
  {
    key: 'Defense',
    get: (unit, build, soul, skills) => {
      if (!unit || !build) return 0
      const key = 'Defense'
      const tag = unit.tags.find((t) => t.tag === 'MeleeAttack')
      const level = build.level || 1
      const iv = (build.iv ? build.iv[key] || 0 : 0) / 100
      const souls = (soul?.bonus || 0) / 100
      const stars = build.stars || 0
      const Defense_Bonus = getSkillsBonus(skills, key)
      return Math.floor(
        Math.floor(50 + tag.number * 0.075 * level * (1 + iv)) *
          (1 + Defense_Bonus) *
          (1 + souls) *
          (1 + stars * 0.05)
      )
    },
  },
]

const StatsCalc = () => {
  const { game } = useContext(GameContext)
  const [units, setUnits] = useState([])
  const [skills, setSkills] = useState([])

  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.substring(1))
  const build = query.build ? JSON.parse(atob(query.build)) : {}
  const unit = units.find((u) => u.slug === build.unit)

  const saveBuild = () => {
    query.build = flatten(build)
    navigate({ search: qs.stringify(query), replace: true })
  }

  return (
    <div className='container-fluid'>
      <div className='iv-calculator'>
        <div>
          <Unit unit={unit || {}} link />
          <UnitSelect
            type='pals'
            value={unit}
            onChange={(option) => {
              build.unit = option.slug
              saveBuild()
            }}
            onLoad={setUnits}
          />
          <p>Level</p>
          <StyledInput
            value={build.level || 1}
            onChange={(e) => {
              build.level = e.target.value
              saveBuild()
            }}
            type='number'
            max={50}
            min={1}
            onFocus={handleFocus}
          />
          <p>Stars</p>
          <StyledSelect
            value={starOptions.find((o) => o.value === build.stars)}
            options={starOptions}
            onChange={(option) => {
              build.stars = option.value
              saveBuild()
            }}
          />
          <div style={{ marginTop: 10 }}>Note: This is a work in progress</div>
        </div>
        <div>
          <h1>Palworld Stats Calculator & IV Calculator</h1>
          <div>
            {stats.map((stat) => {
              const soul = soulOptions.find((o) =>
                build.souls ? o.value === build.souls[stat.key] : false
              )
              const currSkulls = []
              if (build.skills)
                Object.keys(build.skills).forEach((skillIdx) => {
                  const skillId = build.skills[skillIdx]
                  currSkulls.push(skills.find((s) => s.id === skillId))
                })

              return (
                <div className='iv-calc-row'>
                  <span className='soul-input'>
                    <img
                      src={`https://${game.cdn}/palworld/icons/T_icon_buildup.png`}
                      alt='souls'
                      className='soul-icon'
                    />
                    <div className='soul-select'>
                      <StyledSelect
                        value={soul}
                        options={soulOptions}
                        onChange={(option) => {
                          if (!build.souls) build.souls = {}
                          build.souls[stat.key] = option.value
                          saveBuild()
                        }}
                      />
                    </div>
                  </span>
                  <span>{stat.key}</span>
                  <span>
                    IV&nbsp;
                    <StyledInput
                      className='iv-input'
                      value={build.iv ? build.iv[stat.key] || 0 : 0}
                      onChange={(e) => {
                        if (!build.iv) build.iv = {}
                        build.iv[stat.key] = e.target.value
                        saveBuild()
                      }}
                      type='number'
                      max={30}
                      min={0}
                      onFocus={handleFocus}
                    />
                  </span>
                  <span className='iv-stat'>
                    {stat.get(unit, build, soul, currSkulls)}
                  </span>
                </div>
              )
            })}
          </div>
          <h3>Skills</h3>
          <div className='iv-skills'>
            <TagSelect
              category='Passive Skills'
              onLoad={(tags) => {
                setSkills(tags)
              }}
              onChange={(option) => {
                if (!build.skills) build.skills = {}
                build.skills[0] = option.value
                saveBuild()
              }}
              placeholder='Select a skill'
            />
            {skills.length > 0 &&
              [1, 2, 3].map((idx) => {
                const value = build.passiveSkills
                  ? build.passiveSkills[idx]
                  : ''
                return (
                  <TagSelect
                    category='Passive Skills'
                    tags={skills}
                    onChange={(option) => {
                      if (!build.skills) build.skills = {}
                      build.skills[idx] = option.value
                      saveBuild()
                    }}
                    placeholder='Select a skill'
                  />
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsCalc
