import { useState, useEffect, useContext } from 'react'
import { TikTok } from 'react-tiktok'
import { tiktokRegex } from '../../services/util'
import GameContext from '../../contexts/GameContext'

const TIKTOK_OEMBED_BASE_URL = `https://www.tiktok.com/oembed`

const TikTokVideo = ({ link, bg, onLoad }) => {
  const [loaded, setLoaded] = useState(false)
  const { game } = useContext(GameContext)
  useEffect(() => {
    fetch(`${TIKTOK_OEMBED_BASE_URL}?url=${link}`)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.status_msg) return onLoad(false, res.status_msg)
        if (!res || !res.html)
          return onLoad(false, "API response doesn't look right")
        onLoad(true)
        setLoaded(true)
      })
      .catch(() => {
        onLoad(false)
      })
  }, [])

  if (!loaded) return null

  const videoId = link.match(tiktokRegex)
  if (videoId && !videoId[1]) return null

  return (
    <div>
      <div
        style={{
          backgroundImage: bg ? `url(https://${game.cdn}/${bg})` : '',
          backgroundSize: 'cover',
          filter: 'blur(8px)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      ></div>
      <div className='tiktok-container'>
        <div>
          <TikTok url={link} />
        </div>
      </div>
    </div>
  )
}

export default TikTokVideo
