import { useContext } from 'react'
import GameContext from '../../contexts/GameContext'

import Units from '../Units'

import './style.css'

const Steps = ({ steps }) => {
  const { game } = useContext(GameContext)
  return (
    <div>
      <h2>Steps</h2>
      <div>
        {steps.map((step, stepIdx) => {
          return (
            <div className='comp-step'>
              <div className='row'>
                {step.imgs && (
                  <div className='col-xs-12 col-sm-6 col-md-4'>
                    {step.imgs.map((stepImg) => {
                      return (
                        <img
                          src={`https://${game.cdn}/${stepImg}`}
                          alt={`Step ${step.index}`}
                        />
                      )
                    })}
                  </div>
                )}
                <div className='col-xs-12 col-sm-6 col-md-8'>
                  <h3 className='step-title'>
                    Step {step.index || stepIdx + 1}
                  </h3>
                  <div className='step-description'>{step.description}</div>
                  {step.units && <Units units={step.units} />}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Steps
