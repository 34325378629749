import http from './http'

export const getSources = (query) => {
  return http.get('/api/sources', query)
}

export const getSourceUnits = (query) => {
  return http.get('/api/sources/units', query)
}

export const createSources = (body) => {
  return http.post('/api/sources', body)
}

export const trackSources = (body) => {
  return http.post('/api/sources/track', body)
}
export const untrackSources = (query) => {
  return http.del('/api/sources/track', query)
}
