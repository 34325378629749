import { StyledLink } from '../../components/Styled'

import { sortStr } from '../../services/util'

import Unit from '../../components/Units/Unit'

const UnitList = ({ units, search, unitType, sort }) => {
  return (
    <div className='units-page-list'>
      {units
        .filter((u) => u.name.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => {
          if (sort) {
            if (sort.includes('tag_')) {
              const tag = sort.replace('tag_', '')
              const tagA = a.tags?.find((t) => t.tag === tag)
              const tagB = b.tags?.find((t) => t.tag === tag)
              const aI = parseInt(tagA?.string.replace('B', '')) || 1000
              const bI = parseInt(tagB?.string.replace('B', '')) || 1000
              return aI - bI
            } else {
              return sortStr(a, b, sort)
            }
          }
        })
        .map((unit) => {
          return (
            <StyledLink to={`/${unitType}/${unit.slug}`}>
              <Unit unit={unit} />
            </StyledLink>
          )
        })}
    </div>
  )
}

export default UnitList
