const getZonaite = (units) => {
  let cost = 0
  units.forEach((unit) => {
    cost += unit.amount
  })
  return cost * 3
}

const ZonaiteCost = ({ units, size }) => {
  return (
    <div className='zonaite-cost'>
      <img
        src='https://cdn.zeldabuilds.gg/icons/autobuild.png'
        alt='zonaite'
        style={{ width: size || 40 }}
      />
      <img
        src='https://cdn.zeldabuilds.gg/units/Zonaite.webp'
        alt='zonaite'
        style={{ width: size + 4 || 40 }}
      />
      {getZonaite(units)}
    </div>
  )
}

export default ZonaiteCost
