const Sources = ({ sources }) => {
  return (
    <div>
      <div style={{ marginBottom: 5, fontWeight: 'bold' }}>Dispensers</div>
      <div>
        {sources.map((source) => {
          return <div>{source.name}</div>
        })}
      </div>
    </div>
  )
}

export default Sources
