import { useState } from 'react'
import L from 'leaflet'

import { StyledInput, StyledSelect } from '../../components/Styled'
import UnitSelect from '../../components/Units/UnitSelect'

import { createSources } from '../../services/sources'

export const defaultIcons = {
  '/palworld/map/icons/T_icon_compass_00.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_00.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_01.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_01.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_02.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_02.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_03.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_03.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_04.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_04.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_05.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_05.png',
    iconSize: [50, 50],
  }),
  '/palworld/map/icons/T_icon_compass_06.png': new L.Icon({
    iconUrl:
      'https://cdn.zeldabuilds.gg/palworld/map/icons/T_icon_compass_06.png',
    iconSize: [50, 50],
  }),
}

const CreateSource = ({ source, setSource, locations, setLocations, map }) => {
  const [unit, setUnit] = useState({})

  return (
    <div>
      <h3>Create Source</h3>
      <div className='input-row'>
        <div className='input-label'>Name</div>
        <StyledInput
          value={source.name}
          onChange={(e) => {
            source.name = e.target.value
            setSource({ ...source })
          }}
        />
      </div>
      {/* <div className='input-row'>
        <div className='input-label'>Unit</div>
        <UnitSelect
          onChange={(option) => {
            setUnit(option)
          }}
        />
      </div> */}
      {/* <div className='input-row'>
        <div className='input-label'>Radius</div>
        {source.radius}
        <input
          type='range'
          min='1'
          max='101'
          value={source.radius}
          step='10'
          onChange={(e) => {
            source.radius = e.target.value
            setSource({ ...source })
          }}
        />
      </div> */}
      <div className='input-row'>
        <div className='input-label'>Icon</div>
        <div className='map-icons'>
          {Object.keys(defaultIcons).map((icon) => {
            return (
              <button
                className={
                  source?.imgs && source?.imgs[0] === icon ? 'btn' : 'btn-alt'
                }
                onClick={() => {
                  if (icon) {
                    source.imgs = [icon]
                  } else {
                    delete source.imgs
                  }
                  setSource({ ...source })
                }}
              >
                <img src={'https://cdn.zeldabuilds.gg' + icon} />
              </button>
            )
          })}
        </div>
      </div>
      <button
        className='btn'
        onClick={() => {
          const newSource = {
            ...source,
            type: 'user',
            map: map.value,
            units: [{ id: unit.id, name: unit.name }],
            raw: true,
          }
          createSources(newSource).then(() => {
            setUnit({})
            setSource({})
            setLocations([...locations, newSource])
          })
        }}
      >
        Save
      </button>
    </div>
  )
}

export default CreateSource
