import { useContext } from 'react'

import GameContext from '../../contexts/GameContext'

import { camelCaseToWords, sortStr } from '../../services/util'

import './style.css'

const getElement = (str) => {
  const match = str.match(/Element: (\w+)/)
  return match ? match[1] : null
}

const elementToImgURL = {
  Normal: '00',
  Fire: '01',
  Water: '02',
  Electricity: '03',
  Leaf: '04',
  Dark: '05',
  Dragon: '06',
  Earth: '07',
  Ice: '08',
}

const Tags = ({ tags, style, children }) => {
  const { game } = useContext(GameContext)

  const categories = {}
  tags.forEach((tag) => {
    if (tag.format?.banner !== 'left') {
      if (!categories[tag.category]) categories[tag.category] = []
      categories[tag.category].push(tag)
    }
  })

  return (
    <div className='tag-container row' style={style}>
      {Object.keys(categories)
        .sort((a, b) => {
          const catA =
            categories[a][0].format?.categoryIdx !== undefined
              ? categories[a][0].format?.categoryIdx
              : 10000
          const catB =
            categories[b][0].format?.categoryIdx !== undefined
              ? categories[b][0].format?.categoryIdx
              : 10000
          return catA - catB
        })
        .map((category, i) => {
          const isCard = categories[category][0]?.format?.card
          const isLvl = category === 'Work Suitability'
          const isAbility = category === 'Abilities'

          return (
            <div
              className={`tag-category col-xs-12 col-md-${isCard ? '12' : '3'}`}
              key={`tag-category-${i}`}
            >
              <div className='tag-category-title'>{category}</div>
              <div
                style={{
                  display: isCard ? 'grid' : '',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                  gap: 12,
                }}
              >
                {categories[category]
                  .sort((a, b) => {
                    if (a.format?.idx !== undefined)
                      return a.format?.idx - b.format?.idx
                    if (a.number) return a.number - b.number
                    if (a.string)
                      return (
                        parseInt(a.string.replace('Lvl ', '')) -
                        parseInt(b.string.replace('Lvl ', ''))
                      )
                  })
                  .map((tag) => {
                    let elementURL = ''
                    if (isAbility) {
                      elementURL = elementToImgURL[getElement(tag.description)]
                    }

                    return (
                      <div
                        className={
                          'tag ' + (tag.format?.card ? 'tag-card' : '')
                        }
                        key={`tag-${tag.id}`}
                      >
                        <div className='tag-header'>
                          {!tag.format?.info && (
                            <div className='tag-label'>
                              {tag.img && (
                                <img
                                  src={`https://${game.cdn}${tag.img}`}
                                  className='tags-tag-img'
                                />
                              )}
                              {elementURL && (
                                <img
                                  src={`https://${game.cdn}/${game.schema}/tags/T_Icon_element_s_${elementURL}.png`}
                                  className='tags-tag-img'
                                />
                              )}
                              {camelCaseToWords(tag.tag)}
                            </div>
                          )}
                          {tag.number && (
                            <span>
                              {isLvl ? 'Lv ' : ''}
                              {tag.number}
                            </span>
                          )}
                          {tag.string && (
                            <div
                              style={{
                                whiteSpace: tag.format?.card ? 'pre' : '',
                              }}
                            >
                              {tag.string}
                            </div>
                          )}
                        </div>
                        {tag.format?.card && <hr style={{ margin: '10px 0px'}} />}
                        {tag.description && (
                          <div className='tag-description'>
                            {tag.description.split('\n').map((desLine, i) => {
                              return (
                                <div
                                  className='tag-description-line'
                                  key={`tag-decsription-${i}`}
                                >
                                  {desLine}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
      {children}
    </div>
  )
}

export default Tags
