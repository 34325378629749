import { useState } from 'react'
import './style.css'

const Button = ({
  children,
  className,
  disabled,
  id,
  loading: loadingProp,
  onClick,
  style,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <button
      id={id}
      onClick={() => {
        if (onClick) {
          const result = onClick()
          if (typeof result === 'object' && typeof result.then === 'function') {
            setLoading(true)
            result.then(() => {
              setLoading(false)
            })
          }
        }
      }}
      className={
        (className && className.includes('icon') ? '' : 'btn ') + className
      }
      disabled={disabled || loadingProp || loading}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      {(loadingProp || loading) && (
        <div className='lds-ring-1' style={{ marginRight: 5 }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {children}
    </button>
  )
}

export default Button
