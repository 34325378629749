const Ip = ({ ip }) => {
  return (
    <div
      style={{
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#459aea',
        padding: '5px 10px',
        borderRadius: 10,
        maxWidth: 300,
        fontWeight: 'bold',
      }}
    >
      {ip}
      <button
        style={{
          fontWeight: 'bold',
          border: 'none',
          borderRadius: 5,
        }}
        onClick={() => {
          navigator.clipboard.writeText(ip)
        }}
      >
        COPY
      </button>
    </div>
  )
}

export default Ip
