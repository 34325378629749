import { useLocation, useNavigate } from 'react-router-dom'

import CreateComp from '../../components/Comps/CreateComp'

const CompEdit = () => {
  const location = useLocation()
  const navigate = useNavigate

  if (!location.state || (location.state && !location.state.comp)) {
    navigate(-1)
    return null
  }

  return (
    <div className='container-fluid'>
      <CreateComp comp={location.state.comp} />
    </div>
  )
}

export default CompEdit
