import { useEffect, useState } from 'react'

import http from '../../services/http'

import Comps from '../../components/Comps'

const Admin = () => {
  const [comps, setComps] = useState([])

  useEffect(() => {
    http
      .get('/api/comps', { user: true, units: true, active: 'null' })
      .then((res) => {
        if (res.error) return alert(res.error)
        setComps(res.data)
      })
  }, [])

  return (
    <div className='container-fluid'>
      <Comps comps={comps} setComps={setComps} admin />
    </div>
  )
}

export default Admin
