import { useContext } from 'react'

import GameContext from '../../contexts/GameContext'

import ImageC from '../../components/Image'

import { StyledLink } from '../Styled'

const Unit = ({ unit }) => {
  const { game } = useContext(GameContext)

  const tags = {}
  const bannerLeft = []
  const bannerRight = []

  if (unit.tags) {
    unit.tags.forEach((tag) => {
      if (tag.format?.banner) {
        if (tag.format.banner === 'left') {
          bannerLeft.push(tag)
        } else {
          bannerRight.push(tag)
        }
      } else {
        if (!tags[tag.category]) tags[tag.category] = []
        tags[tag.category].push(tag)
      }
    })
  }
  return (
    <div className='units-list-unit'>
      {bannerLeft.length > 0 && (
        <div className='units-page-banner-left'>
          {bannerLeft.map((tag, i) => {
            return tag.img ? (
              <img
                className='unit-list-tag-img'
                key={`units-page-tag-left-${i}`}
                src={`https://${game.cdn}${tag.img}`}
              />
            ) : (
              <span
                className='unit-list-tag-num'
                style={{ padding: '2px 4px', marginBottom: 5 }}
              >
                #{tag.string}
              </span>
            )
          })}
        </div>
      )}
      {bannerRight.length > 0 && (
        <div className='units-page-banner-right'>
          {bannerRight.map((tag, i) => {
            return (
              <span
                className='unit-list-tag-num'
                key={`units-page-tag-right-${i}`}
              >
                <img
                  className='unit-list-tag-img'
                  src={`https://${game.cdn}${tag.img}`}
                />
                <span>{tag.number || '1'}</span>
              </span>
            )
          })}
        </div>
      )}
      <ImageC
        src={`https://${game.cdn}${unit.img || `/units/${unit.id}.png`}`}
      />
      <div className='unit-list-name'>{unit.name}</div>
      {/* <div>
    <div>
      {unit.tags &&
        Object.keys(tags).map((category, i) => {
          return (
            <div key={`unit-tags-${i}`}>
              <span className='unit-list-category'>{category}</span>
              <div className='unit-list-tags'>
                {tags[category].map((tag, j) => {
                  return (
                    <div className='unit-list-tag' key={`unit-list-tag-${j}`}>
                      {tag.tag}: {tag.number}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </div>
  </div> */}
    </div>
  )
}

const UnitC = (props) => {
  const { unit } = props
  const { game } = useContext(GameContext)

  return props.link && unit.slug ? (
    <StyledLink to={`/${game.getText('unit')}s/${unit.slug}`}>
      <Unit {...props} />
    </StyledLink>
  ) : (
    <Unit {...props} />
  )
}

export default UnitC
