import { useContext } from 'react'
import styled from 'styled-components'
import { can } from 'rbac'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../contexts/GameContext'
import { youtubeRegex, tiktokRegex } from '../../services/util'
import http from '../../services/http'

import { StyledLink } from '../Styled'
import AdSlot from '../Ads/AdSlot'
import Button from '../inputs/Button'
// import ZonaiteCost from '../Units/ZonaiteCost'
import Ip from './Ip'
import CompVote from './CompVote'

const CompCard = styled.div`
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: ${({ description }) => (description ? 'flex' : '')};
  /* grid-template-columns: 1fr 3fr auto-fill; */
`

const CompImg = styled.img`
  aspect-ratio: ${({ description }) => (description ? '' : 16 / 9)};
  max-width: 150px;
`

const CompInfo = styled.div`
  padding: 10px 15px;
  bottom: 0;
  background-color: ${({ theme }) => theme.bodyAlt};
  border: ${({ theme }) => theme.border};
  border-radius: 10px;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.bodyAltHover};
  }
`

const CompName = styled.div`
  font-weight: bold;
`

const Comps = ({ comps, setComps, admin, onCompClick, user: profileUser }) => {
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)

  return (
    <div className='row'>
      {comps.map((comp, compIdx) => {
        const { id, name, imgs, units } = comp

        const hasVideo = imgs
          ? imgs.find(
              (img) => img.match(youtubeRegex) || img.match(tiktokRegex)
            )
          : false

        return (
          <>
            <div
              className={`col-xs-12 ${
                game.has('BUILDS:DESCRIPTION') ? '' : 'col-md-4'
              }`}
            >
              <CompCard description={game.has('BUILDS:DESCRIPTION')}>
                <StyledLink
                  to={`/${game.getText('build')}/${id}`}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    if (onCompClick) onCompClick()
                  }}
                >
                  <div>
                    {imgs && (
                      <CompImg
                        description={game.has('BUILDS:DESCRIPTION')}
                        src={`https://${game.cdn}/${imgs[0]}`}
                        alt='comp cover'
                      />
                    )}
                    {(hasVideo || units) && (
                      <div className='comps-tags'>
                        {hasVideo && (
                          <div className='comps-video-tag'>Video</div>
                        )}
                        {/* {units && (
                          <div className='comps-cost-tag'>
                            <ZonaiteCost cost={units.length * 3} size={20} />
                          </div>
                        )} */}
                      </div>
                    )}
                  </div>
                </StyledLink>
                <CompInfo description={game.has('BUILDS:DESCRIPTION')}>
                  <div
                    style={{ display: 'flex', alignItems: 'center', flex: 1 }}
                  >
                    <CompVote
                      comp={comp}
                      onVote={(key, vote, voted) => {
                        comps[compIdx].voted = vote
                        if (vote !== null) {
                          comps[compIdx][key] += 1
                          if (voted !== null)
                            comp[key === 'upvotes' ? 'downvotes' : 'upvotes']--
                        } else {
                          comps[compIdx][key] -= 1
                        }
                        setComps([...comps])
                      }}
                    />
                    <StyledLink
                      to={`/${game.getText('build')}/${id}`}
                      onClick={() => {
                        if (onCompClick) onCompClick()
                      }}
                      className='comp-container'
                    >
                      <div className='comp-left'>
                        <CompName>{name}</CompName>
                        <div>
                          by{' '}
                          {profileUser
                            ? profileUser.username
                            : comp.user?.username}
                        </div>
                        {/* <div style={{ marginBottom: 10 }}>
                          {comp.description.substring(0, 100)}...
                        </div> */}
                      </div>
                      {comp.units && (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {comp.units.map((unit) => {
                            return (
                              <div className='build-page-unit'>
                                <img
                                  src={`https://${game.cdn}${unit.img}`}
                                  className='build-unit-img'
                                />
                                <div className='build-page-unit-name'>
                                  {unit.name}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </StyledLink>
                  </div>
                  {comp.ip && <Ip ip={comp.ip} />}
                  {comp.max_players && (
                    <div>
                      {/* Players Online: {comp.players || 0}/{comp.max_players} */}
                      Players: {comp.max_players}
                    </div>
                  )}
                </CompInfo>
              </CompCard>
              {admin && can(user, 'COMPS:APPROVE') && (
                <div className='btn-bar'>
                  <Button
                    onClick={() => {
                      comps.splice(compIdx, 1)
                      setComps([...comps])
                      return http
                        .post('/api/comps/approve', { comp: id })
                        .then((res) => {
                          if (res.error) return alert(res.error)
                        })
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    className='btn-alt'
                    onClick={() => {
                      comps.splice(compIdx, 1)
                      setComps([...comps])
                      return http
                        .post('/api/comps/deny', { comp: id })
                        .then((res) => {
                          if (res.error) return alert(res.error)
                        })
                    }}
                  >
                    Deny
                  </Button>
                </div>
              )}
            </div>
            {((compIdx !== 0 && (compIdx + 1) % 9 === 0) ||
              (compIdx === comps.length - 1 && comps.length < 9)) && (
              <div className='col-xs-12'>
                <AdSlot name='leaderboard' index={compIdx} />
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default Comps
