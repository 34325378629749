import { useContext } from 'react'
import {
  HiThumbUp,
  HiOutlineThumbUp,
  HiThumbDown,
  HiOutlineThumbDown,
} from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import UserContext from '../../contexts/UserContext'
import http from '../../services/http'

const CompVote = ({ comp, onVote }) => {
  const { user } = useContext(UserContext)

  const navigate = useNavigate()
  const { id, upvotes, downvotes, voted } = comp
  return (
    <div>
      <div className='comp-like-container'>
        <button
          className='comp-like'
          onClick={() => {
            if (!user) return navigate('/signup')
            if (voted === 1) {
              onVote('upvotes', null)
              http.post('/api/comps/unlike', { comp: id }).then((res) => {
                if (res.error) return alert(res.error)
              })
            } else {
              onVote('upvotes', 1, voted)
              http
                .post('/api/comps/like', { comp: id, vote: 1 })
                .then((res) => {
                  if (res.error) return alert(res.error)
                })
            }
          }}
        >
          {voted === 1 ? <HiThumbUp /> : <HiOutlineThumbUp />}
        </button>
        {upvotes || 0}
      </div>
      <div className='comp-like-container'>
        <button
          className='comp-like'
          onClick={() => {
            if (!user) return navigate('/signup')
            if (voted === -1) {
              onVote('downvotes', null)
              http.post('/api/comps/unlike', { comp: id }).then((res) => {
                if (res.error) return alert(res.error)
              })
            } else {
              onVote('downvotes', -1, voted)
              http
                .post('/api/comps/like', { comp: id, vote: -1 })
                .then((res) => {
                  if (res.error) return alert(res.error)
                })
            }
          }}
        >
          {voted === -1 ? <HiThumbDown /> : <HiOutlineThumbDown />}
        </button>
        {downvotes || 0}
      </div>
    </div>
  )
}

export default CompVote
