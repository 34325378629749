import http from './http'

export const getMaps = (query) => {
  return http.get('/api/maps', query)
}

export const createMap = (body) => {
  return http.post('/api/maps', body)
}

const mapBounds = {
  min: {
    X: -582888.0,
    Y: -301000.0,
    Z: 1.0,
  },
  max: {
    X: 335112.0,
    Y: 617000.0,
    Z: 1.0,
  },
}
const mapTotal = mapBounds.max.X - mapBounds.min.X

export const getCoord = (x, y) => {
  return [
    (256 / mapTotal) * (x - mapBounds.max.X),
    (256 / mapTotal) * (y - mapBounds.min.Y),
  ]
}
