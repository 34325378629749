import { useContext } from 'react'

import GameContext from '../../contexts/GameContext'
import { ButtonLink } from '../../components/Styled'

const BuildSubmitted = () => {
  const { game } = useContext(GameContext)
  return (
    <div className='container-fluid'>
      <h1>Thanks for submitting!</h1>
      {game.has('COMPS:APPROVAL') && (
        <div>
          After your build is reviewed it will show up on the home page.
        </div>
      )}
      <ButtonLink style={{ marginTop: 10 }} to='/'>
        Home
      </ButtonLink>
    </div>
  )
}

export default BuildSubmitted
