import { StyledLink } from '../../components/Styled'

const Filter = ({ map, categories, filters, setFilters }) => {
  return (
    <div style={{ paddingTop: 20 }}>
      <div>
        <div>
          {Object.keys(categories).map((category) => {
            const catCount = categories[category]
            return (
              <div
                onClick={() => {
                  setFilters({
                    ...filters,
                    [category]:
                      filters[category] !== undefined
                        ? !filters[category]
                        : true,
                  })
                }}
                style={{ marginBottom: 10 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    textDecoration: filters[category] ? '' : 'line-through',
                  }}
                >
                  <span>{category}</span>
                  <span>{catCount}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Filter
