import { useState } from 'react'

import http from '../../services/http'
import EventSelect from '../../components/Events/EventSelect'

const AddEvent = ({ comp, onAdding }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (newOpen) => {
    if (onAdding) onAdding(newOpen)
    setOpen(newOpen)
  }

  return (
    <div>
      {!open && (
        <button
          className='comp-event-add-title btn'
          onClick={() => {
            toggleOpen(!open)
          }}
        >
          Add Use
        </button>
      )}
      {open && (
        <div className='comp-event-add-title' style={{ display: 'flex' }}>
          <div style={{ marginRight: 10 }}>
            <EventSelect
              onChange={(currEvent) => {
                http.post('/api/events/comps', {
                  event: currEvent.value,
                  comp: comp.id,
                })
                toggleOpen(false)
              }}
            />
          </div>
          <button
            className='btn-alt2'
            onClick={() => {
              toggleOpen(!open)
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  )
}

export default AddEvent
