import { useState, useEffect } from 'react'

import { getSources } from '../../services/sources'
// import { CDN_URL } from '../../services/constants'

import AdSlot from '../../components/Ads/AdSlot'
import Helmet from '../../components/Helmet'
// import ImageC from '../../components/Image'
import Units from '../../components/Units'

import './style.css'

const Sources = () => {
  const [sources, setSources] = useState([])

  useEffect(() => {
    getSources({ units: true }).then((res) => {
      if (res.error) return alert(res.error)
      setSources(res.data)
    })
  }, [])

  return (
    <div className='container-fluid sources-page'>
      <Helmet />
      <div className='row'>
        <div className='col-xs-12 col-md-9'>
          <div className='row'>
            {sources.map((source, sourceIdx) => {
              return (
                <>
                  <div className='col-xs-12 col-md-6 sources-source-container'>
                    <div className='sources-source'>
                      <div className='sources-source-left'>
                        <div className='sources-source-name'>{source.name}</div>
                        {/* <ImageC
                    src={`${CDN_URL}${
                      source.img || `/sources/${source.id}.png`
                    }`}
                    className='sources-source-img'
                  /> */}
                      </div>
                      <div>
                        {source.units && <Units units={source.units} noLabel />}
                      </div>
                    </div>
                  </div>
                  {window.innerWidth < 600 &&
                    sourceIdx !== 0 &&
                    (sourceIdx + 1) % 8 === 0 && (
                      <div className='col-xs-12'>
                        <AdSlot
                          name='leaderboard'
                          index={sourceIdx}
                          mobile={true}
                        />
                      </div>
                    )}
                </>
              )
            })}
          </div>
        </div>
        <div className='col-xs-0 col-md-3'>
          <AdSlot
            name='right-rail'
            containerStyle={{
              alignItems: 'flex-start',
              marginLeft: 10,
              position: 'sticky',
              top: 20,
              maxHeight: 650,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Sources
