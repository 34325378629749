import { useContext } from 'react'

import GameContext from '../../contexts/GameContext'
import ImageC from '../Image'
import { StyledLink } from '../Styled'

import ZonaiteCost from './ZonaiteCost'

const Units = ({ units, noLabel, cost }) => {
  const { game } = useContext(GameContext)
  return (
    <div>
      {!noLabel && (
        <div className='comp-units-title capitalize'>
          {game.getText('unit') + 's'}
        </div>
      )}
      {cost && (
        <div style={{ marginBottom: 10 }}>
          <ZonaiteCost units={units} />
        </div>
      )}
      <div>
        {units.map((unit) => {
          return (
            <StyledLink
              to={`/${game.getText('unit')}s/${unit.slug || unit.id}`}
              key={`unit-${unit.id}`}
            >
              <div className='build-unit'>
                <ImageC
                  src={`https://${game.cdn}${
                    unit.img || `/units/${unit.id}.png`
                  }`}
                  className='build-unit-img'
                />
                <div>
                  {!game.has('BUILDS:NO_AMOUNT') ? unit.amount : ''}{' '}
                  {unit.name + (unit.amount > 1 ? 's' : '')}{' '}
                  {unit.properties
                    ? unit.properties.map((property) => {
                        return ` ${property.string}`
                      })
                    : ''}
                </div>
              </div>
            </StyledLink>
          )
        })}
      </div>
    </div>
  )
}

export default Units
