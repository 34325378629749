export const sortStr = (a, b, key) => {
  const lowerA = a[key] ? a[key].toLowerCase() : a[key]
  const lowerB = b[key] ? b[key].toLowerCase() : b[key]
  if (lowerA < lowerB) {
    return -1
  } else if (lowerA > lowerB) {
    return 1
  } else {
    return 0
  }
}

export const camelCaseToWords = (s) => {
  if (s === s.toUpperCase()) return s
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const youtubeRegex =
  /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/

export const tiktokRegex = /https:\/\/www.tiktok.com\/.*\/?[video]?\/(.*)\??.*/

export const redditRegex = /^https:\/\/www.reddit.com\/r\/.*\/comments\/.*$/

export const capFirst = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
