import { useContext, useEffect, useState } from 'react'

import GameContext from '../../contexts/GameContext'
import { getTags } from '../../services/tags'
import { camelCaseToWords } from '../../services/util'

const statKeyConfig = {
  Stats: 'Sum',
  Elements: 'Count',
  'Work Suitability': 'Count | Avg Lvl',
  'Partner Skill': 'Count',
}

const PalStats = ({ units }) => {
  const { game } = useContext(GameContext)
  const [allTags, setAllTags] = useState([])

  useEffect(() => {
    getTags({ category: 'Work Suitability' }).then((res) => {
      if (res?.data) setAllTags(res.data)
    })
  }, [])

  if (!units) return null
  const stats = {}
  const tagFormat = {}
  let totalPals = 0

  units.forEach((unit) => {
    if (unit.id) {
      totalPals++
    }
    if (unit.tags) {
      unit.tags
        .filter((t) => t.category !== 'Summary' && t.category !== 'Abilities')
        .forEach((tag) => {
          if (!stats[tag.category]) stats[tag.category] = {}
          if (!stats[tag.category][tag.tag])
            stats[tag.category][tag.tag] = {
              count: 0,
              sum: 0,
              img: tag.img,
              idx: tag.format?.idx,
            }
          if (tag.number) stats[tag.category][tag.tag].sum += tag.number
          stats[tag.category][tag.tag].count++
          tagFormat[tag.category] = tag.format
        })
    }
  })

  const renderTable = (tagList, activeTags, total) => {
    const activeTagNames = Object.keys(activeTags)

    return (
      <div className='stat-table'>
        {tagList.map((t) => {
          const activeClass = activeTagNames.includes(t.tag)
            ? 'active'
            : 'inactive'
          const currTag = activeTags[t.tag]
          const tagName = camelCaseToWords(t.tag)
          const avgLvl = Math.round(currTag?.sum / total) || 1

          return (
            <span className={`stat-table-cell ${activeClass}`}>
              <span className='col-1'>
                <img
                  src={`https://${game.cdn}${t.img}`}
                  className={`comp-stats-tag-img ${activeClass}`}
                />
                <span>{tagName}</span>
              </span>
              <span className='col-2'>
                {activeClass === 'active' && (
                  <>
                    {currTag?.count || 'N/A'} | {`Lv ${avgLvl}` || 'N/A'}
                  </>
                )}
              </span>
            </span>
          )
        })}
      </div>
    )
  }

  const renderTag = (tag, category, name) => {
    const count = tag?.count || 'N/A'
    const sumOnly = category === 'Stats'
    const hasSum = tag?.sum > 0
    const categoryClass = category.split(' ').join('')
    const tagName = camelCaseToWords(name)

    return (
      <div className={`stat ${categoryClass}`} key={`stat-${tagName}`}>
        <span className='stat-name'>
          {tag.img ? (
            <img
              src={`https://${game.cdn}${tag.img}`}
              className='comp-stats-tag-img'
            />
          ) : (
            tagName
          )}
        </span>
        <span className='stat-data'>
          {sumOnly ? (
            <>{tag.sum}</>
          ) : category === 'Elements' || category === 'Partner Skill' ? (
            <>({count})</>
          ) : (
            <>{count}</>
          )}
          {!sumOnly && hasSum && <> | {tag.sum}</>}
        </span>
      </div>
    )
  }

  return (
    <div className='tag-stats'>
      {Object.keys(stats)
        .sort((a, b) => {
          const catA =
            tagFormat[a]?.categoryIdx !== undefined
              ? tagFormat[a]?.categoryIdx
              : 1000
          const catB =
            tagFormat[b]?.categoryIdx !== undefined
              ? tagFormat[b]?.categoryIdx
              : 1000
          return catA - catB
        })
        .map((category) => {
          const categoryClass = category.split(' ').join('')
          return (
            <div
              className={`tag-stat-category ${categoryClass}`}
              key={`category-${categoryClass}`}
            >
              <h3 className='tag-stat-title'>
                {category}{' '}
                <span className='tag-stat-title-key'>
                  ( {statKeyConfig[category] || ''} )
                </span>
              </h3>
              {category === 'Work Suitability' ? (
                <div className={`stat-container ${categoryClass}`}>
                  {renderTable(allTags, stats[category], totalPals)}
                </div>
              ) : (
                <div className={`stat-container ${categoryClass}`}>
                  {Object.keys(stats[category])
                    .sort(
                      (a, b) => stats[category][a].idx - stats[category][b].idx
                    )
                    .map((tagName) => {
                      const tag = stats[category][tagName]
                      return <>{renderTag(tag, category, tagName)}</>
                    })}
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default PalStats
